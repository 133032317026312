import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
};

export type Grading = {
  __typename?: 'Grading';
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  discarded?: Maybe<Scalars['Boolean']['output']>;
  grading: Scalars['Int']['output'];
  imageId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['Int']['output'];
  uuid: Scalars['ID']['output'];
};

export type GradingReport = {
  __typename?: 'GradingReport';
  gradings: Scalars['Int']['output'];
  images: Scalars['Int']['output'];
  producerId: Scalars['Int']['output'];
  sessionId: Scalars['ID']['output'];
};

export type Image = {
  __typename?: 'Image';
  camera: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  height: Scalars['Float']['output'];
  producerId: Scalars['Int']['output'];
  rotation: Scalars['Float']['output'];
  sessionId: Scalars['String']['output'];
  tag?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['Int']['output'];
  uuid: Scalars['ID']['output'];
  view?: Maybe<Scalars['String']['output']>;
  width: Scalars['Float']['output'];
  x: Scalars['Float']['output'];
  y: Scalars['Float']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Associate producer user */
  associateProducerUser: Scalars['String']['output'];
  /** Create grading within a session */
  createGrading: Scalars['String']['output'];
  /** Create image within a session */
  createImage: Scalars['String']['output'];
  /** Create producer */
  createProducer: Scalars['String']['output'];
  /** Delete grading within a session */
  deleteGrading: Scalars['String']['output'];
  /** Delete image within a session */
  deleteImage: Scalars['String']['output'];
  /** Create user */
  inviteUser: Scalars['String']['output'];
  /** Update grading within a session */
  updateGrading: Scalars['String']['output'];
  /** Update image within a session */
  updateImage: Scalars['String']['output'];
};


export type MutationAssociateProducerUserArgs = {
  producerId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
};


export type MutationCreateGradingArgs = {
  discarded?: InputMaybe<Scalars['Boolean']['input']>;
  grading: Scalars['Int']['input'];
  imageId: Scalars['String']['input'];
};


export type MutationCreateImageArgs = {
  camera: Scalars['Int']['input'];
  height: Scalars['Float']['input'];
  rotation: Scalars['Float']['input'];
  sessionId: Scalars['String']['input'];
  tag?: InputMaybe<Scalars['String']['input']>;
  timestamp: Scalars['Float']['input'];
  view?: InputMaybe<Scalars['String']['input']>;
  width: Scalars['Float']['input'];
  x: Scalars['Float']['input'];
  y: Scalars['Float']['input'];
};


export type MutationCreateProducerArgs = {
  name: Scalars['String']['input'];
};


export type MutationDeleteGradingArgs = {
  uuid: Scalars['String']['input'];
};


export type MutationDeleteImageArgs = {
  uuid: Scalars['String']['input'];
};


export type MutationInviteUserArgs = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
};


export type MutationUpdateGradingArgs = {
  discarded?: InputMaybe<Scalars['Boolean']['input']>;
  grading: Scalars['Int']['input'];
  uuid: Scalars['String']['input'];
};


export type MutationUpdateImageArgs = {
  camera: Scalars['Int']['input'];
  height: Scalars['Float']['input'];
  rotation: Scalars['Float']['input'];
  sessionId: Scalars['String']['input'];
  tag?: InputMaybe<Scalars['String']['input']>;
  timestamp: Scalars['Float']['input'];
  uuid: Scalars['String']['input'];
  view?: InputMaybe<Scalars['String']['input']>;
  width: Scalars['Float']['input'];
  x: Scalars['Float']['input'];
  y: Scalars['Float']['input'];
};

export type Producer = {
  __typename?: 'Producer';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  users?: Maybe<Array<User>>;
};

export type Query = {
  __typename?: 'Query';
  /** Export the grading information for a session by session ID */
  exportGradingsForSession: Scalars['String']['output'];
  /** Get counts of number of images and how many are graded for all producers and sessions */
  getGradingReports: Array<GradingReport>;
  /** Get all gradings for requested images by their IDs */
  getGradingsByImageIds: Array<Grading>;
  /** Get an upload URL for an image associated with a video */
  getImageUploadUrl: Scalars['String']['output'];
  /** Get all images for a session */
  getImagesForSession: Array<Image>;
  /** Get all producers */
  getProducers: Array<Producer>;
  /** Get all producers across all producers for the requesting user */
  getProducersByUserId: Array<Producer>;
  /** Get authenticated user */
  getSelf?: Maybe<User>;
  /** Get all sessions */
  getSessions: Array<Session>;
  /** Get all sessions across all producers for the requesting user */
  getSessionsByUserId: Array<Session>;
  /** Get user by ID */
  getUser: User;
  /** Get all users */
  getUsers: Array<User>;
};


export type QueryExportGradingsForSessionArgs = {
  sessionId: Scalars['String']['input'];
};


export type QueryGetGradingsByImageIdsArgs = {
  imageIds: Array<Scalars['String']['input']>;
};


export type QueryGetImageUploadUrlArgs = {
  imageId: Scalars['String']['input'];
  sessionId: Scalars['String']['input'];
};


export type QueryGetImagesForSessionArgs = {
  sessionId: Scalars['String']['input'];
};


export type QueryGetUserArgs = {
  id: Scalars['Int']['input'];
};

export type Session = {
  __typename?: 'Session';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  producerId: Scalars['Int']['output'];
  uuid: Scalars['ID']['output'];
};

export type User = {
  __typename?: 'User';
  admin: Scalars['Boolean']['output'];
  cognitoId: Scalars['ID']['output'];
  email: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  producers: Array<Producer>;
};

export type AssociateProducerUserMutationVariables = Exact<{
  producerId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
}>;


export type AssociateProducerUserMutation = { __typename?: 'Mutation', associateProducerUser: string };

export type CreateGradingMutationVariables = Exact<{
  grading: Scalars['Int']['input'];
  imageId: Scalars['String']['input'];
  discarded?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type CreateGradingMutation = { __typename?: 'Mutation', createGrading: string };

export type CreateImageMutationVariables = Exact<{
  camera: Scalars['Int']['input'];
  sessionId: Scalars['String']['input'];
  timestamp: Scalars['Float']['input'];
  x: Scalars['Float']['input'];
  y: Scalars['Float']['input'];
  width: Scalars['Float']['input'];
  height: Scalars['Float']['input'];
  rotation: Scalars['Float']['input'];
  tag?: InputMaybe<Scalars['String']['input']>;
  view?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateImageMutation = { __typename?: 'Mutation', createImage: string };

export type CreateProducerMutationVariables = Exact<{
  name: Scalars['String']['input'];
}>;


export type CreateProducerMutation = { __typename?: 'Mutation', createProducer: string };

export type DeleteGradingMutationVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;


export type DeleteGradingMutation = { __typename?: 'Mutation', deleteGrading: string };

export type DeleteImageMutationVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;


export type DeleteImageMutation = { __typename?: 'Mutation', deleteImage: string };

export type InviteUserMutationVariables = Exact<{
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
}>;


export type InviteUserMutation = { __typename?: 'Mutation', inviteUser: string };

export type UpdateGradingMutationVariables = Exact<{
  uuid: Scalars['String']['input'];
  grading: Scalars['Int']['input'];
  discarded?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type UpdateGradingMutation = { __typename?: 'Mutation', updateGrading: string };

export type UpdateImageMutationVariables = Exact<{
  uuid: Scalars['String']['input'];
  camera: Scalars['Int']['input'];
  sessionId: Scalars['String']['input'];
  timestamp: Scalars['Float']['input'];
  x: Scalars['Float']['input'];
  y: Scalars['Float']['input'];
  width: Scalars['Float']['input'];
  height: Scalars['Float']['input'];
  rotation: Scalars['Float']['input'];
  tag?: InputMaybe<Scalars['String']['input']>;
  view?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateImageMutation = { __typename?: 'Mutation', updateImage: string };

export type ExportGradingsForSessionQueryVariables = Exact<{
  sessionId: Scalars['String']['input'];
}>;


export type ExportGradingsForSessionQuery = { __typename?: 'Query', exportGradingsForSession: string };

export type GetGradingReportsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGradingReportsQuery = { __typename?: 'Query', getGradingReports: Array<{ __typename?: 'GradingReport', producerId: number, sessionId: string, gradings: number, images: number }> };

export type GetGradingsByImageIdsQueryVariables = Exact<{
  imageIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type GetGradingsByImageIdsQuery = { __typename?: 'Query', getGradingsByImageIds: Array<{ __typename?: 'Grading', uuid: string, grading: number, imageId: string, userId: number, createdAt: any, updatedAt: any, deletedAt?: any | null, discarded?: boolean | null }> };

export type GetImageUploadUrlQueryVariables = Exact<{
  imageId: Scalars['String']['input'];
  sessionId: Scalars['String']['input'];
}>;


export type GetImageUploadUrlQuery = { __typename?: 'Query', getImageUploadUrl: string };

export type GetImagesForSessionQueryVariables = Exact<{
  sessionId: Scalars['String']['input'];
}>;


export type GetImagesForSessionQuery = { __typename?: 'Query', getImagesForSession: Array<{ __typename?: 'Image', uuid: string, timestamp: number, camera: number, producerId: number, sessionId: string, userId: number, createdAt: any, updatedAt: any, deletedAt?: any | null, x: number, y: number, width: number, height: number, rotation: number, tag?: string | null, view?: string | null }> };

export type GetProducersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProducersQuery = { __typename?: 'Query', getProducers: Array<{ __typename?: 'Producer', id: number, name: string }> };

export type GetProducersByUserIdQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProducersByUserIdQuery = { __typename?: 'Query', getProducersByUserId: Array<{ __typename?: 'Producer', id: number, name: string }> };

export type GetSelfQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSelfQuery = { __typename?: 'Query', getSelf?: { __typename?: 'User', id: number, email: string, cognitoId: string, name: string, admin: boolean } | null };

export type GetSessionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSessionsQuery = { __typename?: 'Query', getSessions: Array<{ __typename?: 'Session', uuid: string, producerId: number, createdAt?: any | null }> };

export type GetSessionsByUserIdQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSessionsByUserIdQuery = { __typename?: 'Query', getSessionsByUserId: Array<{ __typename?: 'Session', uuid: string, producerId: number, createdAt?: any | null }> };

export type GetUserQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type GetUserQuery = { __typename?: 'Query', getUser: { __typename?: 'User', id: number, email: string, cognitoId: string, name: string, admin: boolean, producers: Array<{ __typename?: 'Producer', id: number, name: string }> } };

export type GetUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsersQuery = { __typename?: 'Query', getUsers: Array<{ __typename?: 'User', id: number, email: string, cognitoId: string, name: string, admin: boolean, producers: Array<{ __typename?: 'Producer', id: number, name: string }> }> };

export type GradingFragFragment = { __typename?: 'Grading', uuid: string, grading: number, imageId: string, userId: number, createdAt: any, updatedAt: any, deletedAt?: any | null, discarded?: boolean | null };

export type GradingReportFragFragment = { __typename?: 'GradingReport', producerId: number, sessionId: string, gradings: number, images: number };

export type ImageFragFragment = { __typename?: 'Image', uuid: string, timestamp: number, camera: number, producerId: number, sessionId: string, userId: number, createdAt: any, updatedAt: any, deletedAt?: any | null, x: number, y: number, width: number, height: number, rotation: number, tag?: string | null, view?: string | null };

export type ProducerFragFragment = { __typename?: 'Producer', id: number, name: string };

export type SessionFragFragment = { __typename?: 'Session', uuid: string, producerId: number, createdAt?: any | null };

export type UserFragFragment = { __typename?: 'User', id: number, email: string, cognitoId: string, name: string, admin: boolean };

export const GradingFragFragmentDoc = gql`
    fragment GradingFrag on Grading {
  uuid
  grading
  imageId
  userId
  createdAt
  updatedAt
  deletedAt
  discarded
}
    `;
export const GradingReportFragFragmentDoc = gql`
    fragment GradingReportFrag on GradingReport {
  producerId
  sessionId
  gradings
  images
}
    `;
export const ImageFragFragmentDoc = gql`
    fragment ImageFrag on Image {
  uuid
  timestamp
  camera
  producerId
  sessionId
  userId
  createdAt
  updatedAt
  deletedAt
  x
  y
  width
  height
  rotation
  tag
  view
}
    `;
export const ProducerFragFragmentDoc = gql`
    fragment ProducerFrag on Producer {
  id
  name
}
    `;
export const SessionFragFragmentDoc = gql`
    fragment SessionFrag on Session {
  uuid
  producerId
  createdAt
}
    `;
export const UserFragFragmentDoc = gql`
    fragment UserFrag on User {
  id
  email
  cognitoId
  name
  admin
}
    `;
export const AssociateProducerUserDocument = gql`
    mutation associateProducerUser($producerId: Int!, $userId: Int!) {
  associateProducerUser(producerId: $producerId, userId: $userId)
}
    `;
export type AssociateProducerUserMutationFn = Apollo.MutationFunction<AssociateProducerUserMutation, AssociateProducerUserMutationVariables>;

/**
 * __useAssociateProducerUserMutation__
 *
 * To run a mutation, you first call `useAssociateProducerUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssociateProducerUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [associateProducerUserMutation, { data, loading, error }] = useAssociateProducerUserMutation({
 *   variables: {
 *      producerId: // value for 'producerId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAssociateProducerUserMutation(baseOptions?: Apollo.MutationHookOptions<AssociateProducerUserMutation, AssociateProducerUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssociateProducerUserMutation, AssociateProducerUserMutationVariables>(AssociateProducerUserDocument, options);
      }
export type AssociateProducerUserMutationHookResult = ReturnType<typeof useAssociateProducerUserMutation>;
export type AssociateProducerUserMutationResult = Apollo.MutationResult<AssociateProducerUserMutation>;
export type AssociateProducerUserMutationOptions = Apollo.BaseMutationOptions<AssociateProducerUserMutation, AssociateProducerUserMutationVariables>;
export const CreateGradingDocument = gql`
    mutation createGrading($grading: Int!, $imageId: String!, $discarded: Boolean) {
  createGrading(grading: $grading, imageId: $imageId, discarded: $discarded)
}
    `;
export type CreateGradingMutationFn = Apollo.MutationFunction<CreateGradingMutation, CreateGradingMutationVariables>;

/**
 * __useCreateGradingMutation__
 *
 * To run a mutation, you first call `useCreateGradingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGradingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGradingMutation, { data, loading, error }] = useCreateGradingMutation({
 *   variables: {
 *      grading: // value for 'grading'
 *      imageId: // value for 'imageId'
 *      discarded: // value for 'discarded'
 *   },
 * });
 */
export function useCreateGradingMutation(baseOptions?: Apollo.MutationHookOptions<CreateGradingMutation, CreateGradingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGradingMutation, CreateGradingMutationVariables>(CreateGradingDocument, options);
      }
export type CreateGradingMutationHookResult = ReturnType<typeof useCreateGradingMutation>;
export type CreateGradingMutationResult = Apollo.MutationResult<CreateGradingMutation>;
export type CreateGradingMutationOptions = Apollo.BaseMutationOptions<CreateGradingMutation, CreateGradingMutationVariables>;
export const CreateImageDocument = gql`
    mutation createImage($camera: Int!, $sessionId: String!, $timestamp: Float!, $x: Float!, $y: Float!, $width: Float!, $height: Float!, $rotation: Float!, $tag: String, $view: String) {
  createImage(
    camera: $camera
    sessionId: $sessionId
    timestamp: $timestamp
    x: $x
    y: $y
    width: $width
    height: $height
    rotation: $rotation
    tag: $tag
    view: $view
  )
}
    `;
export type CreateImageMutationFn = Apollo.MutationFunction<CreateImageMutation, CreateImageMutationVariables>;

/**
 * __useCreateImageMutation__
 *
 * To run a mutation, you first call `useCreateImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createImageMutation, { data, loading, error }] = useCreateImageMutation({
 *   variables: {
 *      camera: // value for 'camera'
 *      sessionId: // value for 'sessionId'
 *      timestamp: // value for 'timestamp'
 *      x: // value for 'x'
 *      y: // value for 'y'
 *      width: // value for 'width'
 *      height: // value for 'height'
 *      rotation: // value for 'rotation'
 *      tag: // value for 'tag'
 *      view: // value for 'view'
 *   },
 * });
 */
export function useCreateImageMutation(baseOptions?: Apollo.MutationHookOptions<CreateImageMutation, CreateImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateImageMutation, CreateImageMutationVariables>(CreateImageDocument, options);
      }
export type CreateImageMutationHookResult = ReturnType<typeof useCreateImageMutation>;
export type CreateImageMutationResult = Apollo.MutationResult<CreateImageMutation>;
export type CreateImageMutationOptions = Apollo.BaseMutationOptions<CreateImageMutation, CreateImageMutationVariables>;
export const CreateProducerDocument = gql`
    mutation createProducer($name: String!) {
  createProducer(name: $name)
}
    `;
export type CreateProducerMutationFn = Apollo.MutationFunction<CreateProducerMutation, CreateProducerMutationVariables>;

/**
 * __useCreateProducerMutation__
 *
 * To run a mutation, you first call `useCreateProducerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProducerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProducerMutation, { data, loading, error }] = useCreateProducerMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateProducerMutation(baseOptions?: Apollo.MutationHookOptions<CreateProducerMutation, CreateProducerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProducerMutation, CreateProducerMutationVariables>(CreateProducerDocument, options);
      }
export type CreateProducerMutationHookResult = ReturnType<typeof useCreateProducerMutation>;
export type CreateProducerMutationResult = Apollo.MutationResult<CreateProducerMutation>;
export type CreateProducerMutationOptions = Apollo.BaseMutationOptions<CreateProducerMutation, CreateProducerMutationVariables>;
export const DeleteGradingDocument = gql`
    mutation deleteGrading($uuid: String!) {
  deleteGrading(uuid: $uuid)
}
    `;
export type DeleteGradingMutationFn = Apollo.MutationFunction<DeleteGradingMutation, DeleteGradingMutationVariables>;

/**
 * __useDeleteGradingMutation__
 *
 * To run a mutation, you first call `useDeleteGradingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGradingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGradingMutation, { data, loading, error }] = useDeleteGradingMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useDeleteGradingMutation(baseOptions?: Apollo.MutationHookOptions<DeleteGradingMutation, DeleteGradingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteGradingMutation, DeleteGradingMutationVariables>(DeleteGradingDocument, options);
      }
export type DeleteGradingMutationHookResult = ReturnType<typeof useDeleteGradingMutation>;
export type DeleteGradingMutationResult = Apollo.MutationResult<DeleteGradingMutation>;
export type DeleteGradingMutationOptions = Apollo.BaseMutationOptions<DeleteGradingMutation, DeleteGradingMutationVariables>;
export const DeleteImageDocument = gql`
    mutation deleteImage($uuid: String!) {
  deleteImage(uuid: $uuid)
}
    `;
export type DeleteImageMutationFn = Apollo.MutationFunction<DeleteImageMutation, DeleteImageMutationVariables>;

/**
 * __useDeleteImageMutation__
 *
 * To run a mutation, you first call `useDeleteImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteImageMutation, { data, loading, error }] = useDeleteImageMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useDeleteImageMutation(baseOptions?: Apollo.MutationHookOptions<DeleteImageMutation, DeleteImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteImageMutation, DeleteImageMutationVariables>(DeleteImageDocument, options);
      }
export type DeleteImageMutationHookResult = ReturnType<typeof useDeleteImageMutation>;
export type DeleteImageMutationResult = Apollo.MutationResult<DeleteImageMutation>;
export type DeleteImageMutationOptions = Apollo.BaseMutationOptions<DeleteImageMutation, DeleteImageMutationVariables>;
export const InviteUserDocument = gql`
    mutation inviteUser($email: String!, $name: String!) {
  inviteUser(email: $email, name: $name)
}
    `;
export type InviteUserMutationFn = Apollo.MutationFunction<InviteUserMutation, InviteUserMutationVariables>;

/**
 * __useInviteUserMutation__
 *
 * To run a mutation, you first call `useInviteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUserMutation, { data, loading, error }] = useInviteUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useInviteUserMutation(baseOptions?: Apollo.MutationHookOptions<InviteUserMutation, InviteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteUserMutation, InviteUserMutationVariables>(InviteUserDocument, options);
      }
export type InviteUserMutationHookResult = ReturnType<typeof useInviteUserMutation>;
export type InviteUserMutationResult = Apollo.MutationResult<InviteUserMutation>;
export type InviteUserMutationOptions = Apollo.BaseMutationOptions<InviteUserMutation, InviteUserMutationVariables>;
export const UpdateGradingDocument = gql`
    mutation updateGrading($uuid: String!, $grading: Int!, $discarded: Boolean) {
  updateGrading(uuid: $uuid, grading: $grading, discarded: $discarded)
}
    `;
export type UpdateGradingMutationFn = Apollo.MutationFunction<UpdateGradingMutation, UpdateGradingMutationVariables>;

/**
 * __useUpdateGradingMutation__
 *
 * To run a mutation, you first call `useUpdateGradingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGradingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGradingMutation, { data, loading, error }] = useUpdateGradingMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      grading: // value for 'grading'
 *      discarded: // value for 'discarded'
 *   },
 * });
 */
export function useUpdateGradingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGradingMutation, UpdateGradingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGradingMutation, UpdateGradingMutationVariables>(UpdateGradingDocument, options);
      }
export type UpdateGradingMutationHookResult = ReturnType<typeof useUpdateGradingMutation>;
export type UpdateGradingMutationResult = Apollo.MutationResult<UpdateGradingMutation>;
export type UpdateGradingMutationOptions = Apollo.BaseMutationOptions<UpdateGradingMutation, UpdateGradingMutationVariables>;
export const UpdateImageDocument = gql`
    mutation updateImage($uuid: String!, $camera: Int!, $sessionId: String!, $timestamp: Float!, $x: Float!, $y: Float!, $width: Float!, $height: Float!, $rotation: Float!, $tag: String, $view: String) {
  updateImage(
    uuid: $uuid
    camera: $camera
    sessionId: $sessionId
    timestamp: $timestamp
    x: $x
    y: $y
    width: $width
    height: $height
    rotation: $rotation
    tag: $tag
    view: $view
  )
}
    `;
export type UpdateImageMutationFn = Apollo.MutationFunction<UpdateImageMutation, UpdateImageMutationVariables>;

/**
 * __useUpdateImageMutation__
 *
 * To run a mutation, you first call `useUpdateImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateImageMutation, { data, loading, error }] = useUpdateImageMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      camera: // value for 'camera'
 *      sessionId: // value for 'sessionId'
 *      timestamp: // value for 'timestamp'
 *      x: // value for 'x'
 *      y: // value for 'y'
 *      width: // value for 'width'
 *      height: // value for 'height'
 *      rotation: // value for 'rotation'
 *      tag: // value for 'tag'
 *      view: // value for 'view'
 *   },
 * });
 */
export function useUpdateImageMutation(baseOptions?: Apollo.MutationHookOptions<UpdateImageMutation, UpdateImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateImageMutation, UpdateImageMutationVariables>(UpdateImageDocument, options);
      }
export type UpdateImageMutationHookResult = ReturnType<typeof useUpdateImageMutation>;
export type UpdateImageMutationResult = Apollo.MutationResult<UpdateImageMutation>;
export type UpdateImageMutationOptions = Apollo.BaseMutationOptions<UpdateImageMutation, UpdateImageMutationVariables>;
export const ExportGradingsForSessionDocument = gql`
    query ExportGradingsForSession($sessionId: String!) {
  exportGradingsForSession(sessionId: $sessionId)
}
    `;

/**
 * __useExportGradingsForSessionQuery__
 *
 * To run a query within a React component, call `useExportGradingsForSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportGradingsForSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportGradingsForSessionQuery({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useExportGradingsForSessionQuery(baseOptions: Apollo.QueryHookOptions<ExportGradingsForSessionQuery, ExportGradingsForSessionQueryVariables> & ({ variables: ExportGradingsForSessionQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportGradingsForSessionQuery, ExportGradingsForSessionQueryVariables>(ExportGradingsForSessionDocument, options);
      }
export function useExportGradingsForSessionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportGradingsForSessionQuery, ExportGradingsForSessionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportGradingsForSessionQuery, ExportGradingsForSessionQueryVariables>(ExportGradingsForSessionDocument, options);
        }
export function useExportGradingsForSessionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ExportGradingsForSessionQuery, ExportGradingsForSessionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ExportGradingsForSessionQuery, ExportGradingsForSessionQueryVariables>(ExportGradingsForSessionDocument, options);
        }
export type ExportGradingsForSessionQueryHookResult = ReturnType<typeof useExportGradingsForSessionQuery>;
export type ExportGradingsForSessionLazyQueryHookResult = ReturnType<typeof useExportGradingsForSessionLazyQuery>;
export type ExportGradingsForSessionSuspenseQueryHookResult = ReturnType<typeof useExportGradingsForSessionSuspenseQuery>;
export type ExportGradingsForSessionQueryResult = Apollo.QueryResult<ExportGradingsForSessionQuery, ExportGradingsForSessionQueryVariables>;
export const GetGradingReportsDocument = gql`
    query GetGradingReports {
  getGradingReports {
    ...GradingReportFrag
  }
}
    ${GradingReportFragFragmentDoc}`;

/**
 * __useGetGradingReportsQuery__
 *
 * To run a query within a React component, call `useGetGradingReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGradingReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGradingReportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGradingReportsQuery(baseOptions?: Apollo.QueryHookOptions<GetGradingReportsQuery, GetGradingReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGradingReportsQuery, GetGradingReportsQueryVariables>(GetGradingReportsDocument, options);
      }
export function useGetGradingReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGradingReportsQuery, GetGradingReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGradingReportsQuery, GetGradingReportsQueryVariables>(GetGradingReportsDocument, options);
        }
export function useGetGradingReportsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetGradingReportsQuery, GetGradingReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetGradingReportsQuery, GetGradingReportsQueryVariables>(GetGradingReportsDocument, options);
        }
export type GetGradingReportsQueryHookResult = ReturnType<typeof useGetGradingReportsQuery>;
export type GetGradingReportsLazyQueryHookResult = ReturnType<typeof useGetGradingReportsLazyQuery>;
export type GetGradingReportsSuspenseQueryHookResult = ReturnType<typeof useGetGradingReportsSuspenseQuery>;
export type GetGradingReportsQueryResult = Apollo.QueryResult<GetGradingReportsQuery, GetGradingReportsQueryVariables>;
export const GetGradingsByImageIdsDocument = gql`
    query GetGradingsByImageIds($imageIds: [String!]!) {
  getGradingsByImageIds(imageIds: $imageIds) {
    ...GradingFrag
  }
}
    ${GradingFragFragmentDoc}`;

/**
 * __useGetGradingsByImageIdsQuery__
 *
 * To run a query within a React component, call `useGetGradingsByImageIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGradingsByImageIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGradingsByImageIdsQuery({
 *   variables: {
 *      imageIds: // value for 'imageIds'
 *   },
 * });
 */
export function useGetGradingsByImageIdsQuery(baseOptions: Apollo.QueryHookOptions<GetGradingsByImageIdsQuery, GetGradingsByImageIdsQueryVariables> & ({ variables: GetGradingsByImageIdsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGradingsByImageIdsQuery, GetGradingsByImageIdsQueryVariables>(GetGradingsByImageIdsDocument, options);
      }
export function useGetGradingsByImageIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGradingsByImageIdsQuery, GetGradingsByImageIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGradingsByImageIdsQuery, GetGradingsByImageIdsQueryVariables>(GetGradingsByImageIdsDocument, options);
        }
export function useGetGradingsByImageIdsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetGradingsByImageIdsQuery, GetGradingsByImageIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetGradingsByImageIdsQuery, GetGradingsByImageIdsQueryVariables>(GetGradingsByImageIdsDocument, options);
        }
export type GetGradingsByImageIdsQueryHookResult = ReturnType<typeof useGetGradingsByImageIdsQuery>;
export type GetGradingsByImageIdsLazyQueryHookResult = ReturnType<typeof useGetGradingsByImageIdsLazyQuery>;
export type GetGradingsByImageIdsSuspenseQueryHookResult = ReturnType<typeof useGetGradingsByImageIdsSuspenseQuery>;
export type GetGradingsByImageIdsQueryResult = Apollo.QueryResult<GetGradingsByImageIdsQuery, GetGradingsByImageIdsQueryVariables>;
export const GetImageUploadUrlDocument = gql`
    query GetImageUploadUrl($imageId: String!, $sessionId: String!) {
  getImageUploadUrl(imageId: $imageId, sessionId: $sessionId)
}
    `;

/**
 * __useGetImageUploadUrlQuery__
 *
 * To run a query within a React component, call `useGetImageUploadUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetImageUploadUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetImageUploadUrlQuery({
 *   variables: {
 *      imageId: // value for 'imageId'
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useGetImageUploadUrlQuery(baseOptions: Apollo.QueryHookOptions<GetImageUploadUrlQuery, GetImageUploadUrlQueryVariables> & ({ variables: GetImageUploadUrlQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetImageUploadUrlQuery, GetImageUploadUrlQueryVariables>(GetImageUploadUrlDocument, options);
      }
export function useGetImageUploadUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetImageUploadUrlQuery, GetImageUploadUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetImageUploadUrlQuery, GetImageUploadUrlQueryVariables>(GetImageUploadUrlDocument, options);
        }
export function useGetImageUploadUrlSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetImageUploadUrlQuery, GetImageUploadUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetImageUploadUrlQuery, GetImageUploadUrlQueryVariables>(GetImageUploadUrlDocument, options);
        }
export type GetImageUploadUrlQueryHookResult = ReturnType<typeof useGetImageUploadUrlQuery>;
export type GetImageUploadUrlLazyQueryHookResult = ReturnType<typeof useGetImageUploadUrlLazyQuery>;
export type GetImageUploadUrlSuspenseQueryHookResult = ReturnType<typeof useGetImageUploadUrlSuspenseQuery>;
export type GetImageUploadUrlQueryResult = Apollo.QueryResult<GetImageUploadUrlQuery, GetImageUploadUrlQueryVariables>;
export const GetImagesForSessionDocument = gql`
    query GetImagesForSession($sessionId: String!) {
  getImagesForSession(sessionId: $sessionId) {
    ...ImageFrag
  }
}
    ${ImageFragFragmentDoc}`;

/**
 * __useGetImagesForSessionQuery__
 *
 * To run a query within a React component, call `useGetImagesForSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetImagesForSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetImagesForSessionQuery({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useGetImagesForSessionQuery(baseOptions: Apollo.QueryHookOptions<GetImagesForSessionQuery, GetImagesForSessionQueryVariables> & ({ variables: GetImagesForSessionQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetImagesForSessionQuery, GetImagesForSessionQueryVariables>(GetImagesForSessionDocument, options);
      }
export function useGetImagesForSessionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetImagesForSessionQuery, GetImagesForSessionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetImagesForSessionQuery, GetImagesForSessionQueryVariables>(GetImagesForSessionDocument, options);
        }
export function useGetImagesForSessionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetImagesForSessionQuery, GetImagesForSessionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetImagesForSessionQuery, GetImagesForSessionQueryVariables>(GetImagesForSessionDocument, options);
        }
export type GetImagesForSessionQueryHookResult = ReturnType<typeof useGetImagesForSessionQuery>;
export type GetImagesForSessionLazyQueryHookResult = ReturnType<typeof useGetImagesForSessionLazyQuery>;
export type GetImagesForSessionSuspenseQueryHookResult = ReturnType<typeof useGetImagesForSessionSuspenseQuery>;
export type GetImagesForSessionQueryResult = Apollo.QueryResult<GetImagesForSessionQuery, GetImagesForSessionQueryVariables>;
export const GetProducersDocument = gql`
    query GetProducers {
  getProducers {
    ...ProducerFrag
  }
}
    ${ProducerFragFragmentDoc}`;

/**
 * __useGetProducersQuery__
 *
 * To run a query within a React component, call `useGetProducersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProducersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProducersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProducersQuery(baseOptions?: Apollo.QueryHookOptions<GetProducersQuery, GetProducersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProducersQuery, GetProducersQueryVariables>(GetProducersDocument, options);
      }
export function useGetProducersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProducersQuery, GetProducersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProducersQuery, GetProducersQueryVariables>(GetProducersDocument, options);
        }
export function useGetProducersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetProducersQuery, GetProducersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetProducersQuery, GetProducersQueryVariables>(GetProducersDocument, options);
        }
export type GetProducersQueryHookResult = ReturnType<typeof useGetProducersQuery>;
export type GetProducersLazyQueryHookResult = ReturnType<typeof useGetProducersLazyQuery>;
export type GetProducersSuspenseQueryHookResult = ReturnType<typeof useGetProducersSuspenseQuery>;
export type GetProducersQueryResult = Apollo.QueryResult<GetProducersQuery, GetProducersQueryVariables>;
export const GetProducersByUserIdDocument = gql`
    query GetProducersByUserId {
  getProducersByUserId {
    ...ProducerFrag
  }
}
    ${ProducerFragFragmentDoc}`;

/**
 * __useGetProducersByUserIdQuery__
 *
 * To run a query within a React component, call `useGetProducersByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProducersByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProducersByUserIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProducersByUserIdQuery(baseOptions?: Apollo.QueryHookOptions<GetProducersByUserIdQuery, GetProducersByUserIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProducersByUserIdQuery, GetProducersByUserIdQueryVariables>(GetProducersByUserIdDocument, options);
      }
export function useGetProducersByUserIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProducersByUserIdQuery, GetProducersByUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProducersByUserIdQuery, GetProducersByUserIdQueryVariables>(GetProducersByUserIdDocument, options);
        }
export function useGetProducersByUserIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetProducersByUserIdQuery, GetProducersByUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetProducersByUserIdQuery, GetProducersByUserIdQueryVariables>(GetProducersByUserIdDocument, options);
        }
export type GetProducersByUserIdQueryHookResult = ReturnType<typeof useGetProducersByUserIdQuery>;
export type GetProducersByUserIdLazyQueryHookResult = ReturnType<typeof useGetProducersByUserIdLazyQuery>;
export type GetProducersByUserIdSuspenseQueryHookResult = ReturnType<typeof useGetProducersByUserIdSuspenseQuery>;
export type GetProducersByUserIdQueryResult = Apollo.QueryResult<GetProducersByUserIdQuery, GetProducersByUserIdQueryVariables>;
export const GetSelfDocument = gql`
    query GetSelf {
  getSelf {
    ...UserFrag
  }
}
    ${UserFragFragmentDoc}`;

/**
 * __useGetSelfQuery__
 *
 * To run a query within a React component, call `useGetSelfQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSelfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSelfQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSelfQuery(baseOptions?: Apollo.QueryHookOptions<GetSelfQuery, GetSelfQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSelfQuery, GetSelfQueryVariables>(GetSelfDocument, options);
      }
export function useGetSelfLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSelfQuery, GetSelfQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSelfQuery, GetSelfQueryVariables>(GetSelfDocument, options);
        }
export function useGetSelfSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetSelfQuery, GetSelfQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSelfQuery, GetSelfQueryVariables>(GetSelfDocument, options);
        }
export type GetSelfQueryHookResult = ReturnType<typeof useGetSelfQuery>;
export type GetSelfLazyQueryHookResult = ReturnType<typeof useGetSelfLazyQuery>;
export type GetSelfSuspenseQueryHookResult = ReturnType<typeof useGetSelfSuspenseQuery>;
export type GetSelfQueryResult = Apollo.QueryResult<GetSelfQuery, GetSelfQueryVariables>;
export const GetSessionsDocument = gql`
    query GetSessions {
  getSessions {
    ...SessionFrag
  }
}
    ${SessionFragFragmentDoc}`;

/**
 * __useGetSessionsQuery__
 *
 * To run a query within a React component, call `useGetSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSessionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSessionsQuery(baseOptions?: Apollo.QueryHookOptions<GetSessionsQuery, GetSessionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSessionsQuery, GetSessionsQueryVariables>(GetSessionsDocument, options);
      }
export function useGetSessionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSessionsQuery, GetSessionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSessionsQuery, GetSessionsQueryVariables>(GetSessionsDocument, options);
        }
export function useGetSessionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetSessionsQuery, GetSessionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSessionsQuery, GetSessionsQueryVariables>(GetSessionsDocument, options);
        }
export type GetSessionsQueryHookResult = ReturnType<typeof useGetSessionsQuery>;
export type GetSessionsLazyQueryHookResult = ReturnType<typeof useGetSessionsLazyQuery>;
export type GetSessionsSuspenseQueryHookResult = ReturnType<typeof useGetSessionsSuspenseQuery>;
export type GetSessionsQueryResult = Apollo.QueryResult<GetSessionsQuery, GetSessionsQueryVariables>;
export const GetSessionsByUserIdDocument = gql`
    query getSessionsByUserId {
  getSessionsByUserId {
    ...SessionFrag
  }
}
    ${SessionFragFragmentDoc}`;

/**
 * __useGetSessionsByUserIdQuery__
 *
 * To run a query within a React component, call `useGetSessionsByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSessionsByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSessionsByUserIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSessionsByUserIdQuery(baseOptions?: Apollo.QueryHookOptions<GetSessionsByUserIdQuery, GetSessionsByUserIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSessionsByUserIdQuery, GetSessionsByUserIdQueryVariables>(GetSessionsByUserIdDocument, options);
      }
export function useGetSessionsByUserIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSessionsByUserIdQuery, GetSessionsByUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSessionsByUserIdQuery, GetSessionsByUserIdQueryVariables>(GetSessionsByUserIdDocument, options);
        }
export function useGetSessionsByUserIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetSessionsByUserIdQuery, GetSessionsByUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSessionsByUserIdQuery, GetSessionsByUserIdQueryVariables>(GetSessionsByUserIdDocument, options);
        }
export type GetSessionsByUserIdQueryHookResult = ReturnType<typeof useGetSessionsByUserIdQuery>;
export type GetSessionsByUserIdLazyQueryHookResult = ReturnType<typeof useGetSessionsByUserIdLazyQuery>;
export type GetSessionsByUserIdSuspenseQueryHookResult = ReturnType<typeof useGetSessionsByUserIdSuspenseQuery>;
export type GetSessionsByUserIdQueryResult = Apollo.QueryResult<GetSessionsByUserIdQuery, GetSessionsByUserIdQueryVariables>;
export const GetUserDocument = gql`
    query GetUser($id: Int!) {
  getUser(id: $id) {
    ...UserFrag
    producers {
      ...ProducerFrag
    }
  }
}
    ${UserFragFragmentDoc}
${ProducerFragFragmentDoc}`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables> & ({ variables: GetUserQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export function useGetUserSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserSuspenseQueryHookResult = ReturnType<typeof useGetUserSuspenseQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetUsersDocument = gql`
    query GetUsers {
  getUsers {
    ...UserFrag
    producers {
      ...ProducerFrag
    }
  }
}
    ${UserFragFragmentDoc}
${ProducerFragFragmentDoc}`;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export function useGetUsersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersSuspenseQueryHookResult = ReturnType<typeof useGetUsersSuspenseQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;