import apolloClient from "apollo";
import * as i from "interfaces";
import * as t from "types";
import { CustomCachingPolicy } from "./images";
import { determineFetchPolicy } from "utils";

type AssociateProducerUserArgs = t.ApiArgs & {
  variables: i.AssociateProducerUserMutationVariables;
};
export const associateProducerUser = async (
  args: AssociateProducerUserArgs
): Promise<void> => {
  const { onError, variables } = args;
  await apolloClient
    .mutate<i.AssociateProducerUserMutation>({
      mutation: i.AssociateProducerUserDocument,
      variables,
    })
    .catch(onError);
};

type CreateProducerArgs = t.ApiArgs & {
  variables: i.CreateProducerMutationVariables;
};
export const createProducer = async (
  args: CreateProducerArgs
): Promise<void> => {
  const { onError, variables } = args;
  await apolloClient
    .mutate<i.CreateProducerMutation>({
      mutation: i.CreateProducerDocument,
      variables,
    })
    .catch(onError);
};

type getProducersArgs = t.ApiArgs & {
  caching?: CustomCachingPolicy;
};
export const getProducers = async (
  args: getProducersArgs
): Promise<i.GetProducersQuery["getProducers"] | undefined> => {
  const { onError, caching } = args;
  const result = await apolloClient
    .query<i.GetProducersQuery>({
      query: i.GetProducersDocument,
      fetchPolicy: determineFetchPolicy(caching),
    })
    .catch(onError);
  return result?.data.getProducers;
};

export const getProducersByUserId = async (
  args: t.ApiArgs
): Promise<i.GetProducersByUserIdQuery["getProducersByUserId"] | undefined> => {
  const { onError } = args;
  const result = await apolloClient
    .query<i.GetProducersByUserIdQuery>({
      query: i.GetProducersByUserIdDocument,
    })
    .catch(onError);
  return result?.data.getProducersByUserId;
};
