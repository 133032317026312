import { Typography } from "antd";

export const EditableTag = ({
  tag,
  onNewTag,
}: {
  tag: string;
  onNewTag: (tag: string) => any;
}) => {
  return (
    <Typography.Paragraph
      onClick={(e) => e.stopPropagation()}
      editable={{
        onChange: (v) => {
          onNewTag(v);
        },
        text: tag,
        tooltip: "Click to edit the tag",
      }}
    >
      {tag}
    </Typography.Paragraph>
  );
};
