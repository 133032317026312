import { ConfigProvider } from "antd";
import { ApolloProvider } from "@apollo/client";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";

import "@aws-amplify/ui-react/styles.css";

import apolloClient from "apollo";
import { Layout } from "components";
import { useSelf } from "hooks";
import { ErrorFallback } from "error-fallback";
import * as p from "pages";

const App = () => {
  const { self } = useSelf();
  if (!self) return null;
  return (
    <BrowserRouter>
      <ConfigProvider>
        <Layout>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Routes>
              {self.admin ? (
                <Route path="images">
                  <Route index element={<p.ImagesList />} />
                  <Route path=":producerId/:sessionId" element={<p.Images />} />
                </Route>
              ) : null}
              <Route path="sessions">
                <Route index element={<p.SessionsList />} />
                <Route path=":producerId/:sessionId" element={<p.Session />} />
              </Route>
              <Route path="producers">
                <Route index element={<p.ProducersList />} />
                {/* <Route path=":id" element={<p.Producer />} /> */}
              </Route>
              <Route path="users">
                <Route index element={<p.UsersList />} />
                <Route path=":id" element={<p.UserDetail />} />
              </Route>
              <Route
                path="*"
                element={
                  <Navigate to={self.admin ? "/images" : "/sessions"} replace />
                }
              />
            </Routes>
          </ErrorBoundary>
        </Layout>
      </ConfigProvider>
    </BrowserRouter>
  );
};

const ProviderApp = () => (
  <ApolloProvider client={apolloClient}>
    <App />
  </ApolloProvider>
);

export default withAuthenticator(ProviderApp, { hideSignUp: true });
