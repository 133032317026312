import { Col, Collapse, Row, Tag } from "antd";
import { ISessionMetadata } from "./session";
import { useMemo } from "react";

export type SessionMetadataProps = {
  metadata?: ISessionMetadata;
  onClickItem: (entry: ISessionMetadata["entries"][0]) => any;
};

export const SessionMetadata = ({
  metadata,
  onClickItem,
}: SessionMetadataProps) => {
  const numCols = 6;
  const colSpan = 24 / numCols;
  const numRows = useMemo(
    () => Math.ceil((metadata?.entries || []).length / numCols),
    [metadata]
  );

  if (!metadata || (metadata?.entries || []).length === 0) return null;

  return (
    <Collapse
      defaultActiveKey={"1"}
      size="small"
      items={[
        {
          key: "1",
          label: "Tag Timestamps",
          children: (
            <Row>
              {[...new Array(numCols)].map((_, colInd) => {
                return (
                  <Col
                    key={`mainCol${colInd}`}
                    span={colSpan}
                    style={
                      colInd > 0
                        ? {
                            borderLeftStyle: "solid",
                            borderLeftWidth: 0.7,
                            borderLeftColor: "rgba(0, 0, 0, 0.5)",
                          }
                        : {}
                    }
                  >
                    {metadata.entries
                      .slice(colInd * numRows, (colInd + 1) * numRows)
                      .map((r, rInd) => {
                        return (
                          <Row
                            key={`subRow${colInd}-${rInd}`}
                            align="middle"
                            justify="center"
                          >
                            <Tag
                              style={{
                                width: "90%",
                                margin: 3,
                                cursor: "pointer",
                              }}
                              onClick={() => onClickItem(r)}
                            >
                              <Row style={{ padding: 5 }}>
                                <Col span={12}>
                                  {new Date(r.start_time * 1000)
                                    .toISOString()
                                    .slice(11, 19)}
                                </Col>
                                <Col span={4}>&#8226;</Col>
                                <Col span={8}>{r.tag}</Col>
                              </Row>
                            </Tag>
                          </Row>
                        );
                      })}
                  </Col>
                );
              })}
            </Row>
          ),
        },
      ]}
    />
  );
};
