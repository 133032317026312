import React from "react";
import { Button, Result } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

type ErrorFallbackProps = {
  error: any;
  resetErrorBoundary: any;
};

export const ErrorFallback: React.FC<ErrorFallbackProps> = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  console.log(props);

  return (
    <Result
      status="500"
      title="Oops! Something went wrong on our end."
      subTitle={`If the problem persists, please copy the error information below and paste it in an email to support.`}
      extra={[
        <Button
          type="primary"
          onClick={() => {
            navigate("/");
            navigate(0);
          }}
          key="home"
        >
          Home
        </Button>,
        <Button onClick={() => navigate(0)} key="try-again">
          Try Again
        </Button>,
      ]}
    >
      <h3>Error Information</h3>
      <table>
        <tr>
          <td>Error:</td>
          <td>{props.error.message}</td>
        </tr>
        <tr>
          <td>Time:</td>
          <td>{new Date().toLocaleString("en-US")}</td>
        </tr>
        <tr>
          <td>Path:</td>
          <td>{`${location.pathname}${location.search}`}</td>
        </tr>
      </table>
    </Result>
  );
};
