import { CSSProperties, useCallback, useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { Stage, Layer } from "react-konva";
import { KonvaEventObject } from "konva/lib/Node";
import { useParams } from "react-router-dom";
import { Buffer } from "buffer";
import { Col, Row, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import { SelectorRect } from "components";
import * as api from "api";
import * as i from "interfaces";
import { useErrorBoundary } from "react-error-boundary";
import axios from "axios";
import { MediaController } from "media-chrome/react";
import { PreviousImages } from "./previous-images";
import { PreviewImage } from "./preview-image";
import { SessionMetadata } from "./session-metadata";
import { SessionControlbar } from "./session-controlbar";

const LOADING_INDICATOR_SIZE = 160;

// Calcuation: (24 * 2) + (26 * 2)
// For (Ant Design Screen Layout Padding * 2) + (Video Padding * 2)
export const CANVAS_OVERFLOW = 48 + 52;

const INITIAL_FPS = 25;

export interface ILocalHoofSelection {
  rotation: number;
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface ILocalStoredHoofSelection extends ILocalHoofSelection {
  timestamp: number;
  camera: number;
  uuid: string;
  sessionId: string;
  producerId: number;
  userId: number;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date;
}

export interface ISessionMetadata {
  cameras: any[]; // TBD
  entries: {
    end_time?: number;
    entry_id: string;
    id: number; // Producer ID?!
    session_id: string;
    start_time: number;
    tag: string; // Cow tag that is within this section starting from `start_time`
  }[];
  id: number; // What is this number for?
  session_id: string;
  upload_finished: string; // Date string
  upload_started: string; // Date string
}

export const Session = () => {
  const [storedRects, setStoredRects] = useState<i.Image[]>([]);
  const [sessionMetadata, setSessionMetadata] = useState<ISessionMetadata>();

  const { showBoundary } = useErrorBoundary();

  const routeParams = useParams<{ producerId: string; sessionId: string }>();

  const shouldUpdatePreviewOnSeekRef = useRef(false);

  const mediaSkipBackwardRef = useRef<HTMLElement>(null);
  const mediaSkipForwardRef = useRef<HTMLElement>(null);
  const mediaSeekRef = useRef<HTMLElement>(null);

  const [isPlaying, setIsPlaying] = useState(false);
  const [submittingOrDeleting, setSubmittingOrDeleting] = useState(false);
  const [videoLoading, setVideoLoading] = useState(false);

  const [cursor, setCursor] = useState<CSSProperties["cursor"]>("unset");

  const videoPlayerRef = useRef<ReactPlayer>(null);
  const [windowScrollY, setWindowScrollY] = useState<number>(0);

  const videoWrapperRef = useRef<HTMLDivElement>(null);

  const boxPreviewRef = useRef<HTMLCanvasElement>(null);
  const [boxPreviewImgData, setBoxPreviewImgData] = useState<string>();

  const [videoPlayerControllerHeight, setVideoPlayerControllerHeight] =
    useState<number>();
  const [videoPlayerBoundingRec, setVideoPlayerBoundingRec] =
    useState<DOMRect>();

  const [rect, setRect] = useState<Partial<i.Image>>();
  const shouldUpdatePreviewOnRectChangeRef = useRef(false);

  const resetLocalRect = useCallback(() => {
    setRect(undefined);
    setBoxPreviewImgData(undefined);
  }, [setRect, setBoxPreviewImgData]);

  const isDrawing = useRef(false);

  const getImagesForSession = useCallback(
    async (caching?: api.CustomCachingPolicy) => {
      if (!routeParams.sessionId) return;

      const variables: i.GetImagesForSessionQueryVariables = {
        sessionId: routeParams.sessionId,
      };
      const imagesRes = await api.getImagesForSession({
        variables,
        caching, // Whether or not to have dedup on for this request
        onError: showBoundary,
      });
      const images = [...(imagesRes || [])];

      // Sort the images by most recent timestamp first
      images.sort((a, b) => a.timestamp - b.timestamp);

      setStoredRects(images);
    },
    [showBoundary, setStoredRects, routeParams.sessionId]
  );

  const getSessionMetadata = useCallback(async () => {
    const res = await axios.get(
      `https://hooftrack.s3.amazonaws.com/${routeParams.producerId}/${routeParams.sessionId}/_session.json`
    );

    const metadata = res.data;

    setSessionMetadata(metadata);

    return metadata;
  }, [routeParams.producerId, routeParams.sessionId]);

  useEffect(() => {
    getImagesForSession();
  }, [routeParams.sessionId, getImagesForSession]);

  useEffect(() => {
    getSessionMetadata();
  }, [
    routeParams.sessionId,
    routeParams.producerId,
    getSessionMetadata,
    setSessionMetadata,
  ]);

  // Set the video chapters once things are loaded
  const setVideoChapters = useCallback(
    async (metadata?: ISessionMetadata) => {
      const video =
        videoPlayerRef.current?.getInternalPlayer() as HTMLVideoElement | null;
      if (!video) return;

      let metadataInternal = metadata;
      if (!metadataInternal) {
        metadataInternal = await getSessionMetadata();
      }
      if (!metadataInternal) return;

      // Now set the video pieces
      const track = video.addTextTrack("chapters", "Chapters", "en");

      metadataInternal.entries.forEach((el, elIndex) => {
        const startTime = el.start_time;
        const endTime =
          el.end_time ??
          elIndex === (metadataInternal?.entries || []).length - 1
            ? video.duration
            : metadataInternal?.entries[elIndex + 1].start_time;
        track.addCue(new VTTCue(startTime, endTime || 1e6, el.tag));
      });
    },
    [getSessionMetadata]
  );

  // Handlers for the skipping forward/backward between tag times
  // Note: These are listeners that attach the click action to the "skip section/animal" buttons on the control bar
  const currMetadataEntryRef = useRef(-1);
  const hasManuallySeekedRef = useRef(false);
  const forwardSeekHandler = useCallback(() => {
    const mediaSkipForwardRefElem = mediaSkipForwardRef.current;
    if (!mediaSkipForwardRefElem) return;

    const videoPlayerInternal =
      videoPlayerRef.current?.getInternalPlayer() as HTMLVideoElement | null;
    if (!videoPlayerInternal) return;

    const nextIndex = Math.min(
      currMetadataEntryRef.current + 1,
      (sessionMetadata?.entries || []).length
    );
    const nextEntry = sessionMetadata?.entries.sort(
      (a, b) => a.start_time - b.start_time
    )[nextIndex];

    const nextTime =
      nextIndex === (sessionMetadata?.entries || []).length
        ? videoPlayerInternal.duration
        : nextEntry?.start_time ?? videoPlayerInternal.duration;

    setVideoLoading(true);

    // TODO: Weird thing that happens with the video where the time we set (say, 1930 sec)
    //       will always be set to 30 sec. in the past (e.g., 1900 sec). Gotta figure this one out eventually...
    videoPlayerInternal.currentTime = nextTime;

    currMetadataEntryRef.current = nextIndex;
    hasManuallySeekedRef.current = false;
  }, [sessionMetadata]);
  const backSeekHandler = useCallback(() => {
    const mediaSkipBackwardRefElem = mediaSkipBackwardRef.current;
    if (!mediaSkipBackwardRefElem) return;

    const videoPlayerInternal =
      videoPlayerRef.current?.getInternalPlayer() as HTMLVideoElement | null;
    if (!videoPlayerInternal) return;

    const previousIndex = Math.max(
      currMetadataEntryRef.current - (hasManuallySeekedRef.current ? 0 : 1),
      -1
    );
    const previousEntry = sessionMetadata?.entries.sort(
      (a, b) => a.start_time - b.start_time
    )[previousIndex];

    const previousTime =
      previousIndex === -1
        ? 0
        : previousEntry?.start_time ?? videoPlayerInternal.duration;

    setVideoLoading(true);

    // TODO: Weird thing that happens with the video where the time we set (say, 1930 sec)
    //       will always be set to 30 sec. in the past (e.g., 1900 sec). Gotta figure this one out eventually...
    videoPlayerInternal.currentTime = previousTime;

    currMetadataEntryRef.current = previousIndex;
    hasManuallySeekedRef.current = false;
  }, [sessionMetadata]);
  // Attach the handlers and deal with manual seeking
  useEffect(() => {
    const mediaSkipBackwardRefElem = mediaSkipBackwardRef.current;
    const mediaSkipForwardRefElem = mediaSkipForwardRef.current;
    const mediaSeekRefElem = mediaSeekRef.current;

    if (!mediaSkipBackwardRefElem && !mediaSkipForwardRefElem) {
      return;
    }

    const videoPlayer = videoPlayerRef.current;
    const videoPlayerInternal =
      videoPlayer?.getInternalPlayer() as HTMLVideoElement | null;
    if (!videoPlayer || !videoPlayerInternal) return;

    mediaSkipBackwardRefElem?.addEventListener("click", backSeekHandler);
    mediaSkipForwardRefElem?.addEventListener("click", forwardSeekHandler);

    const seekHandler = () => {
      setVideoLoading(true);
      hasManuallySeekedRef.current = true;
    };
    mediaSeekRefElem?.addEventListener("click", seekHandler);

    return () => {
      mediaSkipBackwardRefElem?.removeEventListener("click", backSeekHandler);
      mediaSkipForwardRefElem?.removeEventListener("click", forwardSeekHandler);
      mediaSeekRefElem?.removeEventListener("click", seekHandler);
    };
  }, [sessionMetadata, setVideoLoading, backSeekHandler, forwardSeekHandler]);

  const uploadImage = useCallback(
    async (imageId: string, sessionId: string, imgData: string) => {
      // Get the image upload URL
      const imageUploadUrl = await api.getImageUploadUrl({
        variables: {
          imageId,
          sessionId,
        },
        onError: showBoundary,
      });

      if (!imageUploadUrl) return;

      // Upload the image
      const buffer = Buffer.from(
        imgData.replace(/^data:image\/\w+;base64,/, ""),
        "base64"
      );
      await axios.put(imageUploadUrl, buffer, {
        headers: {
          "Content-Type": "image/png",
          "Content-Encoding": "base64",
        },
      });
    },
    [showBoundary]
  );

  // Utility: Get bounding box of rotated rectangle
  const getRotatedRectBoundingBox = useCallback(
    (x: number, y: number, width: number, height: number, rotAngle: number) => {
      // See: https://en.wikipedia.org/wiki/Rotation_of_axes_in_two_dimensions

      const absCos = Math.abs(Math.cos(rotAngle));
      const absSin = Math.abs(Math.sin(rotAngle));

      const cx =
        x +
        (width / 2) * Math.cos(rotAngle) -
        (height / 2) * Math.sin(rotAngle);
      const cy =
        y +
        (width / 2) * Math.sin(rotAngle) +
        (height / 2) * Math.cos(rotAngle);

      const w = width * absCos + height * absSin;
      const h = width * absSin + height * absCos;

      return {
        cx,
        cy,
        width: w,
        height: h,
      };
    },
    []
  );

  // Utility: Get midpoint of rectangle
  const getRectangleLocation = useCallback(
    (x: number, y: number, width: number, height: number, rotAngle: number) => {
      const rotatedRectInfo = getRotatedRectBoundingBox(
        x,
        y,
        width,
        height,
        rotAngle
      );

      const videoPlayer =
        videoPlayerRef.current?.getInternalPlayer() as HTMLVideoElement | null;
      if (!videoPlayer) return;

      const percentX = rotatedRectInfo.cx / videoPlayer.videoWidth;
      const percentY = rotatedRectInfo.cy / videoPlayer.videoHeight;

      const octant = Math.ceil(percentX * 4) + (percentY >= 0.5 ? 4 : 0);

      return octant;
    },
    [getRotatedRectBoundingBox]
  );

  // Utility: Get tag associated with timestamp
  const getTagFromTimestamp = useCallback(
    (timestamp: number) => {
      if (!sessionMetadata) return;

      const video =
        videoPlayerRef.current?.getInternalPlayer() as HTMLVideoElement | null;
      if (!video) return;

      const timeMap = sessionMetadata.entries.map((entry, entryInd) => {
        const startTime = entry.start_time;
        const endTime =
          entry.end_time ??
          entryInd === (sessionMetadata?.entries || []).length - 1
            ? video.duration
            : sessionMetadata?.entries[entryInd + 1].start_time ?? 1e6;

        return {
          tag: entry.tag,
          range: {
            startTime,
            endTime,
          },
        };
      });

      const foundTag = timeMap
        .filter(
          (v) => v.range.startTime <= timestamp && v.range.endTime > timestamp
        )
        .pop()?.tag;

      return foundTag;
    },
    [sessionMetadata]
  );

  const setRectInfo = useCallback(() => {
    if (isDrawing.current || rect?.height === 0 || rect?.width === 0) return;
    const videoPlayer =
      videoPlayerRef.current?.getInternalPlayer() as HTMLVideoElement | null;
    const canvas = boxPreviewRef.current;
    if (!canvas || !videoPlayer || !rect) return;

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    // Create 2 temporary canvases
    const canvas1 = document.createElement("canvas");
    const ctx1 = canvas1.getContext("2d");
    const canvas2 = document.createElement("canvas");
    const ctx2 = canvas2.getContext("2d");
    if (!ctx1 || !ctx2) return;

    // Calculate the video ratios of the displayed video to the actual size of it
    const videoWidthRatio =
      videoPlayer.videoWidth / (videoPlayerBoundingRec?.width ?? 1);
    const videoHeightRatio =
      videoPlayer.videoHeight / (videoPlayerBoundingRec?.height ?? 1);

    if (
      rect.width === undefined ||
      rect.height === undefined ||
      rect.x === undefined ||
      rect.y === undefined ||
      rect.rotation === undefined
    )
      return;

    const sourceX = rect.width >= 0 ? rect.x : rect.x + rect.width;
    const sourceY = rect.height >= 0 ? rect.y : rect.y + rect.height;
    const sourceWidth = Math.abs(rect.width);
    const sourceHeight = Math.abs(rect.height);

    const rectVideoX = videoWidthRatio * sourceX;
    const rectVideoY = videoHeightRatio * sourceY;
    const rectVideoWidth = videoWidthRatio * sourceWidth;
    const rectVideoHeight = videoHeightRatio * sourceHeight;

    // Get the bounding box of the rotated selection area
    const rotAngleRad = rect.rotation * (Math.PI / 180);
    var rectBoundingBox = getRotatedRectBoundingBox(
      rectVideoX,
      rectVideoY,
      rectVideoWidth,
      rectVideoHeight,
      rotAngleRad
    );
    const rectLoc = getRectangleLocation(
      rectVideoX,
      rectVideoY,
      rectVideoWidth,
      rectVideoHeight,
      rotAngleRad
    );

    // Get the tag number of the animal from the timestamp (if possible)
    const timestamp = videoPlayer.currentTime;
    const tag = getTagFromTimestamp(timestamp);

    // Clip the bounding box of the rotated selection area to a temporary canvas
    canvas1.width = canvas2.width = rectBoundingBox.width;
    canvas1.height = canvas2.height = rectBoundingBox.height;

    ctx1.drawImage(
      videoPlayer,
      rectBoundingBox.cx - rectBoundingBox.width / 2,
      rectBoundingBox.cy - rectBoundingBox.height / 2,
      rectBoundingBox.width,
      rectBoundingBox.height,
      0,
      0,
      rectBoundingBox.width,
      rectBoundingBox.height
    );

    // Unrotate the selection area on the temporary canvas
    ctx2.translate(canvas1.width / 2, canvas1.height / 2);
    ctx2.rotate(-rotAngleRad);
    ctx2.drawImage(canvas1, -canvas1.width / 2, -canvas1.height / 2);

    // Draw the selection area to the display canvas
    var offX = rectBoundingBox.width / 2 - rectVideoWidth / 2;
    var offY = rectBoundingBox.height / 2 - rectVideoHeight / 2;

    canvas.width = rectVideoWidth;
    canvas.height = rectVideoHeight;
    ctx.drawImage(canvas2, -offX, -offY);

    setBoxPreviewImgData(canvas.toDataURL());

    setRect({
      ...rect,
      camera: rectLoc,
      timestamp,
      tag,
    });
  }, [
    rect,
    videoPlayerBoundingRec,
    getRectangleLocation,
    getRotatedRectBoundingBox,
    getTagFromTimestamp,
  ]);
  // When the rect changes update the selection
  useEffect(() => {
    if (shouldUpdatePreviewOnRectChangeRef.current) {
      shouldUpdatePreviewOnRectChangeRef.current = false;
      setRectInfo();
    }
  }, [rect, setRectInfo]);

  const handleMouseDown = (e: KonvaEventObject<MouseEvent>) => {
    const clickedOnEmpty = e.target === e.target.getStage();
    if (!clickedOnEmpty || videoLoading || submittingOrDeleting || isPlaying)
      return;
    isDrawing.current = true;
    const pos = e.target?.getStage()?.getPointerPosition();

    if (pos)
      setRect({
        // When drawing a new rectangle, reset the UUID and other info to make sure we store a new rect
        rotation: 0,
        x: pos.x - CANVAS_OVERFLOW / 2,
        y: pos.y - CANVAS_OVERFLOW / 2,
        width: 0,
        height: 0,
      });
  };

  const handleMouseMove = (e: KonvaEventObject<MouseEvent>) => {
    // no drawing - skipping
    if (!isDrawing.current) return;

    const stage = e.target.getStage();
    const point = stage?.getPointerPosition();

    if (point && rect && rect.x && rect.y)
      setRect({
        ...rect,
        width: point.x - (rect.x + CANVAS_OVERFLOW / 2),
        height: point.y - (rect.y + CANVAS_OVERFLOW / 2),
      });
  };

  const handleMouseUp = () => {
    isDrawing.current = false;

    setRectInfo();
  };

  // Get the bounding rectangle for the location of the video player
  const storeVideoPlayerRect = useCallback(() => {
    if (videoPlayerRef.current && videoPlayerRef.current.getInternalPlayer()) {
      const videoPlayer =
        videoPlayerRef.current.getInternalPlayer() as HTMLVideoElement | null; // Checked the type and this is `any` typed, but we should always have HTML `video` elements here
      if (!videoPlayer) return;

      const box = videoPlayer.getBoundingClientRect();

      if (videoPlayer.videoWidth > 0 && videoPlayer.videoHeight > 0) {
        setVideoPlayerControllerHeight(
          box.width * (videoPlayer.videoHeight / videoPlayer.videoWidth)
        );
      }

      setVideoPlayerBoundingRec(box);
    }
  }, [setVideoPlayerBoundingRec, setVideoPlayerControllerHeight]);

  useEffect(() => {
    // Watch for changes in the video PLAYER AND WRAPPER size
    const resizeObserverVideoPlayer = new ResizeObserver(storeVideoPlayerRect);
    const resizeObserverVideoWrapper = new ResizeObserver(storeVideoPlayerRect);

    if (videoPlayerRef.current?.getInternalPlayer()) {
      resizeObserverVideoPlayer.observe(
        videoPlayerRef.current.getInternalPlayer() as HTMLVideoElement
      );
    }

    if (videoWrapperRef.current) {
      resizeObserverVideoWrapper.observe(videoWrapperRef.current);
    }

    return () => {
      resizeObserverVideoWrapper.disconnect();
      resizeObserverVideoPlayer.disconnect();
    }; // clean up
  }, [storeVideoPlayerRect]);

  // Listen to the height
  const handleScroll = useCallback(() => {
    setWindowScrollY(window.scrollY || window.pageYOffset);

    storeVideoPlayerRect();
  }, [storeVideoPlayerRect]);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  const handleSubmit = useCallback(
    async (submitRect: typeof rect) => {
      if (!submitRect) return;

      const canvas = boxPreviewRef.current;
      if (!canvas) {
        console.error("No image data found! Not storing!");
        return;
      }

      if (
        !submitRect.camera ||
        !routeParams.sessionId ||
        submitRect.timestamp === undefined ||
        submitRect.width === undefined ||
        submitRect.height === undefined ||
        submitRect.x === undefined ||
        submitRect.y === undefined ||
        submitRect.rotation === undefined
      )
        return;

      setSubmittingOrDeleting(true);

      const variables: i.CreateImageMutationVariables = {
        camera: submitRect.camera,
        sessionId: routeParams.sessionId,
        timestamp: submitRect.timestamp,
        x: submitRect.x,
        y: submitRect.y,
        width: submitRect.width,
        height: submitRect.height,
        rotation: submitRect.rotation,
        tag: submitRect.tag,
        view: submitRect.view,
      };
      let imageUuid;

      if (submitRect.uuid) {
        // If an existing submitRect exists then update it
        imageUuid = await api.updateImage({
          onError: showBoundary,
          variables: {
            ...variables,
            uuid: submitRect.uuid,
          },
        });
      } else {
        // If not UUID is known, then we are storing a new image
        imageUuid = await api.createImage({
          onError: showBoundary,
          variables,
        });
      }

      if (!imageUuid) {
        setSubmittingOrDeleting(false);
        return;
      }

      // Test upload
      await uploadImage(
        imageUuid,
        routeParams.sessionId || "unknown",
        canvas.toDataURL("image/png", 1.0)
      );

      // Reset selection
      resetLocalRect();

      setSubmittingOrDeleting(false);

      // Reload the images for the session
      await getImagesForSession("no-cache"); // Deactivate cache usage for this request
    },
    [
      getImagesForSession,
      resetLocalRect,
      routeParams.sessionId,
      showBoundary,
      uploadImage,
      setSubmittingOrDeleting,
    ]
  );

  const handleDelete = useCallback(
    async (deleteRect: typeof rect) => {
      if (!deleteRect || !deleteRect?.uuid) return;

      setSubmittingOrDeleting(true);

      const variables: i.DeleteImageMutationVariables = {
        uuid: deleteRect.uuid, // We know it's here thanks to the check before this
      };

      await api.deleteImage({
        onError: showBoundary,
        variables,
      });

      resetLocalRect();

      setSubmittingOrDeleting(false);

      await getImagesForSession("no-cache"); // Deactivate cache usage for this request
    },
    [showBoundary, resetLocalRect, getImagesForSession, setSubmittingOrDeleting]
  );

  /*
  NOTE: This is a way to get around the fact that `ReactPlayer` below doesn't allow us to not have a wrapper element!
        And we need a wrapper element since for `MediaController` to work properly the only child can be the `video` element.
        You can pass `wrapper={React.Fragment}` to `ReactPlayer`, but that will cause a ton of errors as `ReactPlayer` passes
        extra (unavoidable) props to the wrapper. Trying to have a `wrapper` that only uses the `children` passed to it was
        also attempted, but it didn't work (using something like `wrapper={({children}: any) => <>{children}</>}`). Though,
        with more research this _may_ work. Another options would be to ditch `ReactPlayer` in favor of a classic `video` element,
        but I like the ease and flexibility of `ReactPlayer`... For now.
  */
  const hasFixedVideoRef = useRef(false);
  useEffect(() => {
    if (hasFixedVideoRef.current) return;

    const vp =
      videoPlayerRef.current?.getInternalPlayer() as HTMLVideoElement | null;
    if (vp && vp.parentElement?.tagName.toLowerCase() === "div") {
      vp.parentElement.replaceWith(vp);
      hasFixedVideoRef.current = true;
    }
  });

  // Make sure we know which cursor to display!
  useEffect(() => {
    setCursor(
      submittingOrDeleting ? "not-allowed" : isPlaying ? "unset" : "crosshair"
    );
  }, [setCursor, submittingOrDeleting, isPlaying]);

  // Listen for keypresses
  const handlingKeyUpRef = useRef(false);
  const isPlayingRef = useRef(isPlaying);
  useEffect(() => {
    isPlayingRef.current = isPlaying;
  }, [isPlaying]);
  const handleKeyDown = useCallback((e: KeyboardEvent) => {
    const key = e.code;
    if (key === "Space" || key === "ArrowRight" || key === "ArrowLeft") {
      e.preventDefault();
    }
  }, []);
  const handleKeyUp = useCallback(
    (e: KeyboardEvent) => {
      if (handlingKeyUpRef.current) return;

      const key = e.code;

      e.preventDefault();
      handlingKeyUpRef.current = true;

      if (key === "Space") {
        /* SPACE BAR PRESS */
        setIsPlaying(!isPlayingRef.current);
      } else if (!e.shiftKey && !isPlayingRef.current && key === "ArrowLeft") {
        /* FRAME BACKWARD PRESS */
        // For now assume FPS
        const videoPlayer = videoPlayerRef.current;
        if (!videoPlayer) return;

        videoPlayer.seekTo(
          videoPlayer.getCurrentTime() - 1 / INITIAL_FPS,
          "seconds"
        );
        resetLocalRect();
      } else if (!e.shiftKey && !isPlayingRef.current && key === "ArrowRight") {
        /* FRAME FORWARD PRESS */
        // For now assume 30 FPS
        const videoPlayer = videoPlayerRef.current;
        if (!videoPlayer) return;

        videoPlayer.seekTo(
          videoPlayer.getCurrentTime() + 1 / INITIAL_FPS,
          "seconds"
        );
        resetLocalRect();
      }

      setTimeout(() => {
        handlingKeyUpRef.current = false;
      }, 10);
    },
    [resetLocalRect]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown, true);
    document.addEventListener("keyup", handleKeyUp, true);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, [
    setIsPlaying,
    resetLocalRect,
    isPlaying,
    backSeekHandler,
    forwardSeekHandler,
    handleKeyDown,
    handleKeyUp,
  ]);

  return (
    <div id="page-content-wrapper">
      <canvas ref={boxPreviewRef} hidden />
      <div
        id="video-and-preview-wrapper"
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <div
          ref={videoWrapperRef}
          style={{ width: "calc(100% - 200px)", height: "auto", padding: 26 }}
        >
          <MediaController
            id="controller"
            style={{
              width: "100%",
              height: videoPlayerControllerHeight ?? 350,
            }}
          >
            <ReactPlayer
              config={{
                file: {
                  attributes: {
                    crossOrigin: "anonymous",
                    slot: "media",
                  },
                },
              }}
              ref={videoPlayerRef}
              playing={isPlaying}
              className="react-player"
              width="100%"
              height="100%"
              url={`https://hooftrack.s3.amazonaws.com/${routeParams.producerId}/${routeParams.sessionId}/playlist.m3u8`}
              pip={false}
              onReady={() => {
                storeVideoPlayerRect();
                setVideoChapters(sessionMetadata);
                setVideoLoading(false);
              }}
              onPause={() => setIsPlaying(false)}
              onPlay={() => {
                setIsPlaying(true);
                resetLocalRect();
              }}
              controls={false}
              loop={false}
              muted={true}
              onSeek={() => {
                if (shouldUpdatePreviewOnSeekRef.current) {
                  setRectInfo();
                  shouldUpdatePreviewOnSeekRef.current = false;
                } else {
                  resetLocalRect();
                }
                setVideoLoading(false);

                // Find where we are at in terms of the tag timestamps and store if for seeking reasons above
                const videoPlayerInternal =
                  videoPlayerRef.current?.getInternalPlayer() as HTMLVideoElement | null;
                if (!videoPlayerInternal) return;
                currMetadataEntryRef.current =
                  (sessionMetadata?.entries || []).filter(
                    (e) => e.start_time <= videoPlayerInternal.currentTime
                  ).length - 1;
              }}
              onProgress={() => {
                if (videoPlayerControllerHeight === undefined) {
                  storeVideoPlayerRect();
                }
              }}
            />
          </MediaController>

          {videoLoading ? (
            <Spin
              size="large"
              indicator={
                <LoadingOutlined
                  spin
                  style={{
                    fontSize: LOADING_INDICATOR_SIZE,
                  }}
                />
              }
              style={{
                padding: 10,
                borderRadius: 10,
                background: "rgba(0, 0, 0, 0.4)",
                position: "absolute",
                left: videoPlayerBoundingRec
                  ? videoPlayerBoundingRec.left +
                    videoPlayerBoundingRec.width / 2 -
                    LOADING_INDICATOR_SIZE / 2 -
                    10
                  : 0,
                top:
                  (videoPlayerBoundingRec
                    ? videoPlayerBoundingRec.top +
                      videoPlayerBoundingRec.height / 2 -
                      LOADING_INDICATOR_SIZE / 2 -
                      10
                    : 0) + windowScrollY,
                zIndex: 5,
              }}
            />
          ) : null}

          {!videoLoading ? (
            <Stage
              listening={!videoLoading && !submittingOrDeleting}
              width={
                videoPlayerBoundingRec?.width
                  ? videoPlayerBoundingRec?.width + CANVAS_OVERFLOW
                  : 0
              }
              height={
                videoPlayerBoundingRec?.height
                  ? videoPlayerBoundingRec?.height + CANVAS_OVERFLOW
                  : 0
              }
              style={{
                position: "absolute",
                left: videoPlayerBoundingRec?.left
                  ? videoPlayerBoundingRec?.left - CANVAS_OVERFLOW / 2
                  : 0,
                top:
                  (videoPlayerBoundingRec?.top
                    ? videoPlayerBoundingRec?.top - CANVAS_OVERFLOW / 2
                    : 0) + windowScrollY,
                zIndex: 10,
                cursor,
              }}
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              onMouseLeave={handleMouseUp}
            >
              <Layer>
                {rect && (
                  <SelectorRect
                    rotation={rect.rotation}
                    x={rect.x}
                    y={rect.y}
                    width={rect.width}
                    height={rect.height}
                    stroke="red"
                    strokeWidth={3}
                    shadowBlur={3}
                    draggable
                    onChange={(r) => {
                      shouldUpdatePreviewOnRectChangeRef.current = true;
                      setRect({ ...rect, ...r });
                    }}
                    groupX={
                      videoPlayerBoundingRec?.left ? CANVAS_OVERFLOW / 2 : 0
                    }
                    groupY={
                      videoPlayerBoundingRec?.top ? CANVAS_OVERFLOW / 2 : 0
                    }
                    groupWidth={videoPlayerBoundingRec?.width ?? 0}
                    groupHeight={videoPlayerBoundingRec?.height ?? 0}
                    groupClipFunc={(ctx) => {
                      ctx.save();
                      ctx.rect(
                        0,
                        0,
                        videoPlayerBoundingRec?.width ?? 0,
                        videoPlayerBoundingRec?.height ?? 0
                      );
                      ctx.restore();
                    }}
                    onMouseEnter={() => setCursor("grab")}
                    onMouseLeave={() =>
                      setCursor(
                        submittingOrDeleting
                          ? "not-allowed"
                          : isPlaying
                          ? "unset"
                          : "crosshair"
                      )
                    }
                  />
                )}
              </Layer>
            </Stage>
          ) : null}

          <SessionControlbar
            frameStepDeactivated={isPlaying}
            mediaSeekRef={mediaSeekRef}
            mediaSkipBackwardRef={mediaSkipBackwardRef}
            mediaSkipForwardRef={mediaSkipForwardRef}
          />
        </div>

        {rect && boxPreviewImgData && !isDrawing.current ? (
          <PreviewImage
            loading={videoLoading || submittingOrDeleting}
            title="Preview:"
            imageRect={rect}
            setImageRect={setRect}
            imgPreviewData={boxPreviewImgData}
            onSubmit={handleSubmit}
            onDiscard={resetLocalRect}
            onDelete={handleDelete}
            onViewSelectionChange={(v) => setRect({ ...rect, view: v })}
          />
        ) : null}
      </div>
      <div id="history-wrapper">
        <Row>
          <Col span={24}>
            <SessionMetadata
              metadata={sessionMetadata}
              onClickItem={(entry) => {
                const videoPlayer = videoPlayerRef.current;
                if (!videoPlayer) return;

                setVideoLoading(true);
                videoPlayer.seekTo(entry.start_time, "seconds");
                setRect(rect);
                shouldUpdatePreviewOnSeekRef.current = true;
                window.scrollTo({ top: 0 });
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <PreviousImages
              title="Existing selections:"
              imageRects={storedRects}
              onClick={(rect: i.Image) => {
                const videoPlayer = videoPlayerRef.current;
                if (!videoPlayer) return;

                setVideoLoading(true);
                videoPlayer.seekTo(rect.timestamp, "seconds");
                setRect(rect);
                shouldUpdatePreviewOnSeekRef.current = true;
                window.scrollTo({ top: 0 });
              }}
              updateImage={async (img) => {
                setSubmittingOrDeleting(true);

                const variables: i.UpdateImageMutationVariables = {
                  uuid: img.uuid,
                  camera: img.camera,
                  sessionId: img.sessionId,
                  timestamp: img.timestamp,
                  x: img.x,
                  y: img.y,
                  width: img.width,
                  height: img.height,
                  rotation: img.rotation,
                  tag: img.tag,
                  view: img.view,
                };

                // If an existing img exists then update it
                await api.updateImage({
                  onError: showBoundary,
                  variables,
                });

                setSubmittingOrDeleting(false);

                // Reload the images for the session
                await getImagesForSession("no-cache"); // Deactivate cache usage for this request
              }}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};
