import apolloClient from "apollo";
import * as i from "interfaces";
import * as t from "types";

import { CustomCachingPolicy } from "./images";
import { determineFetchPolicy } from "utils";
import { QueryOptions } from "@apollo/client";

type getGradingsByImageIdsArgs = t.ApiArgs & {
  variables: i.GetGradingsByImageIdsQueryVariables;
  caching?: CustomCachingPolicy;
};
export const getGradingsByImageIds = async (
  args: getGradingsByImageIdsArgs
): Promise<
  i.GetGradingsByImageIdsQuery["getGradingsByImageIds"] | undefined
> => {
  const { variables, caching, onError } = args;

  const result = await apolloClient
    .query<i.GetGradingsByImageIdsQuery>({
      variables,
      query: i.GetGradingsByImageIdsDocument,
      fetchPolicy: determineFetchPolicy(caching),
    })
    .catch(onError);
  return result?.data.getGradingsByImageIds;
};

type CreateGradingArgs = t.ApiArgs & {
  variables: i.CreateGradingMutationVariables;
};
export const createGrading = async (
  args: CreateGradingArgs
): Promise<i.CreateGradingMutation["createGrading"] | undefined> => {
  const { onError, variables } = args;
  const gradingUuidRes = await apolloClient
    .mutate<i.CreateGradingMutation>({
      mutation: i.CreateGradingDocument,
      variables,
    })
    .catch(onError);
  return gradingUuidRes?.data?.createGrading;
};

type UpdateGradingArgs = t.ApiArgs & {
  variables: i.UpdateGradingMutationVariables;
};
export const updateGrading = async (
  args: UpdateGradingArgs
): Promise<i.UpdateGradingMutation["updateGrading"] | undefined> => {
  const { onError, variables } = args;
  const gradingUuidRes = await apolloClient
    .mutate<i.UpdateGradingMutation>({
      mutation: i.UpdateGradingDocument,
      variables,
    })
    .catch(onError);
  return gradingUuidRes?.data?.updateGrading;
};

type getGradingReportsArgs = t.ApiArgs & {
  caching?: CustomCachingPolicy;
};
export const getGradingReports = async (
  args: getGradingReportsArgs
): Promise<i.GetGradingReportsQuery["getGradingReports"] | undefined> => {
  const { onError, caching } = args;
  const gradingUuidRes = await apolloClient
    .query<i.GetGradingReportsQuery>({
      query: i.GetGradingReportsDocument,
      fetchPolicy: determineFetchPolicy(caching),
    })
    .catch(onError);
  return gradingUuidRes?.data?.getGradingReports;
};

type ExportGradingsForSessionArgs = t.ApiArgs & {
  variables: i.GetImagesForSessionQueryVariables;
  caching?: CustomCachingPolicy;
};
export const exportGradingsForSession = async (
  args: ExportGradingsForSessionArgs
): Promise<
  i.ExportGradingsForSessionQuery["exportGradingsForSession"] | undefined
> => {
  const { onError, variables, caching } = args;

  let fetchPolicy: QueryOptions["fetchPolicy"];
  if (caching !== undefined) {
    if (typeof caching === "boolean") {
      fetchPolicy = caching ? "cache-first" : "no-cache";
    } else {
      fetchPolicy = caching;
    }
  }
  const result = await apolloClient
    .query<i.ExportGradingsForSessionQuery>({
      query: i.ExportGradingsForSessionDocument,
      variables,
      fetchPolicy,
    })
    .catch(onError);
  return result?.data.exportGradingsForSession;
};
