import { QueryOptions } from "@apollo/client";
import apolloClient from "apollo";
import * as i from "interfaces";
import * as t from "types";

type GetImageUploadUrlArgs = t.ApiArgs & {
  variables: i.GetImageUploadUrlQueryVariables;
};
export const getImageUploadUrl = async (
  args: GetImageUploadUrlArgs
): Promise<i.GetImageUploadUrlQuery["getImageUploadUrl"] | undefined> => {
  const { onError, variables } = args;
  const result = await apolloClient
    .query<i.GetImageUploadUrlQuery>({
      query: i.GetImageUploadUrlDocument,
      variables,
    })
    .catch(onError);
  return result?.data.getImageUploadUrl;
};

type CreateImageArgs = t.ApiArgs & {
  variables: i.CreateImageMutationVariables;
};
export const createImage = async (
  args: CreateImageArgs
): Promise<i.CreateImageMutation["createImage"] | undefined> => {
  const { onError, variables } = args;
  const imageUuidRes = await apolloClient
    .mutate<i.CreateImageMutation>({
      mutation: i.CreateImageDocument,
      variables,
    })
    .catch(onError);
  return imageUuidRes?.data?.createImage;
};

type UpdateImageArgs = t.ApiArgs & {
  variables: i.UpdateImageMutationVariables;
};
export const updateImage = async (
  args: UpdateImageArgs
): Promise<i.UpdateImageMutation["updateImage"] | undefined> => {
  const { onError, variables } = args;
  const imageUuidRes = await apolloClient
    .mutate<i.UpdateImageMutation>({
      mutation: i.UpdateImageDocument,
      variables,
    })
    .catch(onError);
  return imageUuidRes?.data?.updateImage;
};

type DeleteImageArgs = t.ApiArgs & {
  variables: i.DeleteImageMutationVariables;
};
export const deleteImage = async (
  args: DeleteImageArgs
): Promise<i.DeleteImageMutation["deleteImage"] | undefined> => {
  const { onError, variables } = args;
  const imageUuidRes = await apolloClient
    .mutate<i.DeleteImageMutation>({
      mutation: i.DeleteImageDocument,
      variables,
    })
    .catch(onError);
  return imageUuidRes?.data?.deleteImage;
};

export type CustomCachingPolicy = boolean | QueryOptions["fetchPolicy"];
type GetImagesForSessionArgs = t.ApiArgs & {
  variables: i.GetImagesForSessionQueryVariables;
  caching?: CustomCachingPolicy;
};
export const getImagesForSession = async (
  args: GetImagesForSessionArgs
): Promise<i.GetImagesForSessionQuery["getImagesForSession"] | undefined> => {
  const { onError, variables, caching } = args;

  let fetchPolicy: QueryOptions["fetchPolicy"];
  if (caching !== undefined) {
    if (typeof caching === "boolean") {
      fetchPolicy = caching ? "cache-first" : "no-cache";
    } else {
      fetchPolicy = caching;
    }
  }
  const result = await apolloClient
    .query<i.GetImagesForSessionQuery>({
      query: i.GetImagesForSessionDocument,
      variables,
      fetchPolicy,
    })
    .catch(onError);
  return result?.data.getImagesForSession;
};
