import { gray } from "@ant-design/colors";
import { UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Dropdown } from "antd";

import { useSelf } from "hooks";

export const ProfileMenu: React.FC = () => {
  const { signOut } = useSelf();

  return (
    <Dropdown
      menu={{
        items: [
          {
            key: "signOut",
            label: (
              <Button onClick={signOut} type="text">
                Sign Out
              </Button>
            ),
          },
        ],
      }}
      placement="bottomRight"
    >
      <Avatar
        icon={<UserOutlined />}
        style={{ backgroundColor: gray.primary, cursor: "pointer" }}
      />
    </Dropdown>
  );
};
