import apolloClient from "apollo";
import * as i from "interfaces";
import * as t from "types";
import { CustomCachingPolicy } from "./images";
import { determineFetchPolicy } from "utils";

type getSessionssArgs = t.ApiArgs & {
  caching?: CustomCachingPolicy;
};
export const getSessions = async (
  args: getSessionssArgs
): Promise<i.GetSessionsQuery["getSessions"] | undefined> => {
  const { onError, caching } = args;
  const result = await apolloClient
    .query<i.GetSessionsQuery>({
      query: i.GetSessionsDocument,
      fetchPolicy: determineFetchPolicy(caching),
    })
    .catch(onError);
  return result?.data.getSessions;
};

export const getSessionsByUserId = async (
  args: t.ApiArgs
): Promise<i.GetSessionsByUserIdQuery["getSessionsByUserId"] | undefined> => {
  const { onError } = args;
  const result = await apolloClient
    .query<i.GetSessionsByUserIdQuery>({
      query: i.GetSessionsByUserIdDocument,
    })
    .catch(onError);
  return result?.data.getSessionsByUserId;
};
