import React from "react";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, Form, Modal, Table, Input } from "antd";
import { useErrorBoundary } from "react-error-boundary";
import { Link, useNavigate } from "react-router-dom";

import * as api from "api";
import { useSelf } from "hooks";
import * as i from "interfaces";

export const UsersList = () => {
  const { self } = useSelf();
  const { showBoundary } = useErrorBoundary();
  const [users, setUsers] = React.useState<i.GetUsersQuery["getUsers"]>([]);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  React.useEffect(() => {
    const getUsers = async () => {
      const users = await api.getUsers({ onError: showBoundary });
      setUsers(users || []);
    };
    getUsers();
  }, [showBoundary]);

  type Row = { key: number; email: string; numProducers: number };
  const dataSource: Row[] = users.map((user) => ({
    key: user.id,
    name: user.name,
    email: user.email,
    numProducers: user.producers.length,
  }));

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name: string, record: Row) => (
        <Link to={`/users/${record.key}`}>{name}</Link>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Producers",
      dataIndex: "numProducers",
      key: "numProducers",
    },
  ];

  return (
    <>
      {self?.admin && (
        <Button
          style={{ marginBottom: 10 }}
          type="primary"
          onClick={() => setModalOpen(true)}
        >
          <PlusCircleOutlined />
          Invite User
        </Button>
      )}
      <Table dataSource={dataSource} columns={columns} />
      <Modal
        title="Invite User"
        open={modalOpen}
        onCancel={() => setModalOpen(false)}
        onOk={form.submit}
        okText="Invite"
      >
        <Form
          form={form}
          onFinish={async (variables: { name: string; email: string }) => {
            await api.inviteUser({
              onError: showBoundary,
              variables: { ...variables, name: variables.name || "" },
            });
            setModalOpen(false);
            navigate(0);
          }}
        >
          <Form.Item label="Name" name="name">
            <Input />
          </Form.Item>
          <Form.Item label="Email" name="email" rules={[{ type: "email" }]}>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
