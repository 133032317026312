import React, { ComponentProps, useCallback, useState } from "react";
import { Group, Rect, Transformer } from "react-konva";

import { KonvaEventObject } from "konva/lib/Node";
import mergeRefs from "merge-refs";

export type SelectorRectProps = ComponentProps<typeof Rect> & {
  groupX: ComponentProps<typeof Group>["x"];
  groupY: ComponentProps<typeof Group>["y"];
  groupWidth: ComponentProps<typeof Group>["width"];
  groupHeight: ComponentProps<typeof Group>["height"];
  groupClipFunc: ComponentProps<typeof Group>["clipFunc"];

  onChange: (newAttrs: {
    rotation: number;
    x: number;
    y: number;
    width: number;
    height: number;
  }) => void;
};
export const SelectorRect = (props: SelectorRectProps) => {
  const shapeRef: ComponentProps<typeof Rect>["ref"] = React.useRef(null);
  const trRef: ComponentProps<typeof Transformer>["ref"] = React.useRef(null);

  const propsOnMouseEnter = props.onMouseEnter;
  const propsOnMouseLeave = props.onMouseLeave;

  const hideTransformerTimeout = React.useRef<
    ReturnType<typeof setTimeout> | number
  >();

  const [hasMouseEntered, setHasMouseEntered] = useState(false);

  const onMouseEnter = useCallback(
    (e: KonvaEventObject<MouseEvent>) => {
      clearTimeout(hideTransformerTimeout.current);
      setHasMouseEntered(true);

      props.onMouseEnter && props.onMouseEnter(e);

      // we need to attach transformer manually
      if (trRef.current && shapeRef.current)
        trRef.current?.nodes([shapeRef.current]);
      trRef.current?.getLayer()?.batchDraw();

      // Let the props also act if it's there
      if (propsOnMouseEnter) propsOnMouseEnter(e);
    },
    [propsOnMouseEnter, props]
  );

  const onMouseLeaveClear = useCallback(
    (e: KonvaEventObject<MouseEvent>) => {
      setHasMouseEntered(false);

      // Let the props also act if it's there
      if (propsOnMouseLeave) propsOnMouseLeave(e);
    },
    [propsOnMouseLeave]
  );

  const onMouseLeave = useCallback(
    (e: KonvaEventObject<MouseEvent>) => {
      props.onMouseLeave && props.onMouseLeave(e);
      hideTransformerTimeout.current = setTimeout(onMouseLeaveClear, 1000);
    },
    [onMouseLeaveClear, props]
  );

  return (
    <>
      <Group
        x={props.groupX}
        y={props.groupY}
        width={props.groupWidth}
        height={props.groupHeight}
        clipFunc={props.groupClipFunc}
      >
        <Rect
          // @TODO: Properly figure out why the heck `React.LegacyRef<Rect> | undefined` isn't an acceptable type here
          {...props}
          ref={mergeRefs(shapeRef, props.ref as any | undefined)}
          x={props.x}
          y={props.y}
          width={props.width}
          height={props.height}
          fillEnabled={props.fillEnabled === false ? false : true} // If it is explicitly false, then let it be
          fill={props.fill ?? "transparent"}
          stroke={props.stroke ?? "red"}
          strokeWidth={props.strokeWidth ?? 3}
          shadowBlur={props.shadowBlur ?? 3}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onDragEnd={(e) => {
            props.onChange({
              rotation: e.target.rotation(),
              x: e.target.x(),
              y: e.target.y(),
              width: e.target.width(),
              height: e.target.height(),
            });
          }}
          onTransformEnd={(e) => {
            // transformer is changing scale of the node
            // and NOT its width or height
            // but in the store we have only width and height
            // to match the data better we will reset scale on transform end
            const node = shapeRef.current;
            if (!node) return;

            const scaleX = node.scaleX();
            const scaleY = node.scaleY();

            // we will reset it back
            node.scaleX(1);
            node.scaleY(1);
            props.onChange({
              rotation: node.rotation(),
              x: node.x(),
              y: node.y(),
              // set minimal value
              width: node.width() * scaleX,
              height: node.height() * scaleY,
            });
          }}
        />
      </Group>
      {hasMouseEntered && (
        <Transformer
          x={props.x}
          y={props.y}
          ref={trRef}
          flipEnabled={false}
          rotateEnabled={true}
          rotateAnchorOffset={15}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          keepRatio={false}
        />
      )}
    </>
  );
};
