import { ComponentProps, useEffect, useState } from "react";
import { Table } from "antd";
import { useErrorBoundary } from "react-error-boundary";

import * as api from "api";
import * as i from "interfaces";
import { Link } from "react-router-dom";

export const SessionsList = () => {
  const { showBoundary } = useErrorBoundary();
  const [sessions, setSessions] = useState<
    i.GetSessionsByUserIdQuery["getSessionsByUserId"]
  >([]);
  const [producers, setProducers] = useState<
    i.GetProducersQuery["getProducers"]
  >([]);

  useEffect(() => {
    const getSessions = async () => {
      const sessions = await api.getSessionsByUserId({ onError: showBoundary });
      setSessions(sessions || []);
    };
    getSessions();
  }, [showBoundary]);

  useEffect(() => {
    const getProducers = async () => {
      const producers = await api.getProducersByUserId({
        onError: showBoundary,
      });
      setProducers(producers || []);
    };
    getProducers();
  }, [showBoundary]);

  const dataSource = sessions.map((s) => ({
    key: s.uuid,
    session: s.uuid,
    producer:
      producers.filter((p) => p.id === s.producerId).pop()?.name || "Unknown",
    producerId: s.producerId,
    createdAt: s.createdAt
      ? new Date(s.createdAt).toLocaleString("en-US")
      : "Unknown",
  }));

  const columns = [
    {
      title: "Session ID",
      dataIndex: "session",
      key: "session",
      render: (sessionId, record, _) => (
        <Link to={`/sessions/${record.producerId}/${sessionId}`}>
          {sessionId}
        </Link>
      ),
    },
    {
      title: "Producer",
      dataIndex: "producer",
      key: "producer",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
    },
  ] as ComponentProps<typeof Table>["columns"];

  return <Table dataSource={dataSource} columns={columns} />;
};
