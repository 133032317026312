import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client";
import { from } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { removeTypenameFromVariables } from "@apollo/client/link/remove-typename";
import { fetchAuthSession } from "aws-amplify/auth";

const removeTypenameLink = removeTypenameFromVariables();

const httpLink = new HttpLink({
  uri:
    process.env.NODE_ENV === "development"
      ? "http://localhost:8000/graphql"
      : "https://api.hooftrack.net/graphql",
});

const authLink = setContext(async (_, { headers }) => {
  try {
    const session = await fetchAuthSession();
    const jwtToken = session.tokens?.accessToken?.toString();
    if (!jwtToken) {
      throw new Error("No JWT token found");
    }
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${jwtToken}`,
      },
    };
  } catch (e) {
    return {
      headers,
    };
  }
});

const link = from([removeTypenameLink, httpLink]);

const apolloClient = new ApolloClient({
  link: authLink.concat(link),
  cache: new InMemoryCache(),
});

export default apolloClient;
