import apolloClient from "apollo";
import * as i from "interfaces";
import * as t from "types";
import { CustomCachingPolicy } from "./images";
import { QueryOptions } from "@apollo/client";

type getSelfArgs = t.ApiArgs & {
  caching?: CustomCachingPolicy;
};
export const getSelf = async (
  args: getSelfArgs
): Promise<i.GetSelfQuery["getSelf"] | undefined> => {
  const { onError, caching } = args;

  let fetchPolicy: QueryOptions["fetchPolicy"];
  if (caching !== undefined) {
    if (typeof caching === "boolean") {
      fetchPolicy = caching ? "cache-first" : "no-cache";
    } else {
      fetchPolicy = caching;
    }
  }

  const result = await apolloClient
    .query<i.GetSelfQuery>({
      query: i.GetSelfDocument,
      fetchPolicy,
    })
    .catch(onError);
  return result?.data.getSelf;
};

type GerUserArgs = t.ApiArgs & {
  variables: i.GetUserQueryVariables;
};
export const getUser = async (
  args: GerUserArgs
): Promise<i.GetUserQuery["getUser"] | undefined> => {
  const { onError, variables } = args;
  const result = await apolloClient
    .query<i.GetUserQuery>({
      query: i.GetUserDocument,
      variables,
    })
    .catch(onError);
  return result?.data.getUser;
};

export const getUsers = async (
  args: t.ApiArgs
): Promise<i.GetUsersQuery["getUsers"] | undefined> => {
  const { onError } = args;
  const result = await apolloClient
    .query<i.GetUsersQuery>({
      query: i.GetUsersDocument,
    })
    .catch(onError);
  return result?.data.getUsers;
};

type InviteUserArgs = t.ApiArgs & {
  variables: i.InviteUserMutationVariables;
};
export const inviteUser = async (args: InviteUserArgs): Promise<void> => {
  const { onError, variables } = args;
  await apolloClient
    .mutate<i.InviteUserMutation>({
      mutation: i.InviteUserDocument,
      variables,
    })
    .catch(onError);
};
