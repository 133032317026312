import React, { ComponentProps, useEffect, useRef } from "react";
import { Progress, Table } from "antd";
import { useErrorBoundary } from "react-error-boundary";

import * as api from "api";
import * as i from "interfaces";
import { Link, useNavigate } from "react-router-dom";
import { useSelf } from "hooks";

export const ImagesList = () => {
  const { showBoundary } = useErrorBoundary();

  const { self } = useSelf();

  const navigate = useNavigate();

  const forceRefreshRef = useRef({
    session: true,
    producer: true,
    gradingReport: true,
  });

  const [sessions, setSessions] = React.useState<
    i.GetSessionsByUserIdQuery["getSessionsByUserId"]
  >([]);
  const [producers, setProducers] = React.useState<
    i.GetProducersQuery["getProducers"]
  >([]);
  const [gradingReports, setGradingReports] = React.useState<
    i.GetGradingReportsQuery["getGradingReports"]
  >([]);

  useEffect(() => {
    if (!self || !navigate) return;

    if (!self.admin) {
      navigate(-1);
    }
  }, [self, navigate]);

  useEffect(() => {
    const getSessions = async () => {
      const sessions = await api.getSessions({
        onError: showBoundary,
        caching: !forceRefreshRef.current.session,
      });
      forceRefreshRef.current.session = false;
      setSessions(sessions || []);
    };
    getSessions();
  }, [showBoundary]);

  useEffect(() => {
    const getProducers = async () => {
      const producers = await api.getProducers({
        onError: showBoundary,
        caching: !forceRefreshRef.current.producer,
      });
      forceRefreshRef.current.producer = false;
      setProducers(producers || []);
    };
    getProducers();
  }, [showBoundary]);

  useEffect(() => {
    const getGradingReports = async () => {
      const gradingReports = await api.getGradingReports({
        onError: showBoundary,
        caching: !forceRefreshRef.current.gradingReport,
      });
      forceRefreshRef.current.gradingReport = false;
      setGradingReports(gradingReports || []);
    };
    getGradingReports();
  }, [showBoundary]);

  const dataSource = sessions.map((s) => {
    const tmpProducerName =
      producers.filter((p) => p.id === s.producerId).pop()?.name || "Unknown";
    const tmpGradingReport = gradingReports
      .filter((gr) => gr.sessionId === s.uuid)
      .pop();
    const numImages = tmpGradingReport?.images ?? 0;
    const numGradings = tmpGradingReport?.gradings ?? 0;
    return {
      key: s.uuid,
      session: s.uuid,
      producer: tmpProducerName || "Unknown",
      producerId: s.producerId,
      images: numImages,
      gradings: numGradings,
      progress: numImages > 0 ? numGradings / numImages : undefined,
      createdAt: s.createdAt
        ? new Date(s.createdAt).toLocaleString("en-US")
        : "Unknown",
    };
  });

  const columns = [
    {
      title: "Session ID",
      dataIndex: "session",
      key: "session",
      render: (sessionId, record, _) => (
        <Link to={`/images/${record.producerId}/${sessionId}`}>
          {sessionId}
        </Link>
      ),
    },
    {
      title: "Producer",
      dataIndex: "producer",
      key: "producer",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "Num Images",
      dataIndex: "images",
      key: "images",
    },
    {
      title: "Num Graded",
      dataIndex: "gradings",
      key: "gradings",
    },
    {
      title: "Grading Progress",
      dataIndex: "progress",
      key: "progress",
      render: (v, _, __) => (
        <Progress
          percent={Math.round(v * 100) ?? 100}
          strokeColor={!v ? "gray" : undefined}
          status={!v ? "exception" : "active"}
          size="small"
        />
      ),
    },
  ] as ComponentProps<typeof Table>["columns"];

  return <Table dataSource={dataSource} columns={columns} />;
};
