import React from "react";
import { Layout as AntLayout, theme } from "antd";

import { ProfileMenu } from "./profile-menu";
import { Sider } from "./sider";

const { Content, Header } = AntLayout;

export const Layout: React.FC<React.PropsWithChildren> = ({
  children,
}: React.PropsWithChildren) => {
  const { token } = theme.useToken();

  return (
    <AntLayout>
      <Header>
        <div style={{ float: "right" }}>
          <ProfileMenu />
        </div>
      </Header>
      <AntLayout>
        <AntLayout>
          <Sider />
          <Content
            style={{
              minHeight: "100vh",
              background: "transparent",
              borderRadius: token.borderRadiusLG,
              padding: 24,
            }}
          >
            <div>{children}</div>
          </Content>
        </AntLayout>
      </AntLayout>
    </AntLayout>
  );
};
