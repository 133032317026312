import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useErrorBoundary } from "react-error-boundary";
import {
  Button,
  Descriptions,
  Image,
  Segmented,
  Table,
  TableColumnsType,
} from "antd";

import * as api from "api";
import * as i from "interfaces";
import { imageDataToUrl } from "utils/constants";
import { EditableTag } from "components/editable-tag";
import axios from "axios";

export const Images = () => {
  const routeParams = useParams<{ producerId: string; sessionId: string }>();

  const forceImageReload = useRef(true);

  const { showBoundary } = useErrorBoundary();

  const [loadingGradingInfo, setLoadingGradingInfo] = useState(false);
  const [exportingData, setExportingData] = useState(false);

  const [storedRects, setStoredRects] = useState<i.Image[]>([]);
  const [storedGradings, setStoredGradings] = useState<i.Grading[]>([]);

  const [producers, setProducers] = useState<
    i.GetProducersQuery["getProducers"]
  >([]);

  const [tableItems, setTableItems] = useState<
    {
      key: string | number;
      image: i.Image;
      imageRect: i.Image;
      grading?: i.Grading;
    }[]
  >([]);

  const getImagesForSession = useCallback(
    async (caching?: api.CustomCachingPolicy) => {
      if (!routeParams.sessionId) return;

      const variables: i.GetImagesForSessionQueryVariables = {
        sessionId: routeParams.sessionId,
      };
      const imagesRes = await api.getImagesForSession({
        variables,
        caching, // Whether or not to have dedup on for this request
        onError: showBoundary,
      });
      const images = [...(imagesRes || [])];

      // Sort the images by most recent timestamp first
      images.sort((a, b) => a.timestamp - b.timestamp);

      setStoredRects(images);
      return images;
    },
    [showBoundary, setStoredRects, routeParams.sessionId]
  );

  const getGradingsForImages = useCallback(
    async (imageIds: string[], caching?: api.CustomCachingPolicy) => {
      const variables: i.GetGradingsByImageIdsQueryVariables = {
        imageIds,
      };
      const gradingsRes = await api.getGradingsByImageIds({
        variables,
        caching, // Whether or not to have dedup on for this request
        onError: showBoundary,
      });
      const gradings = [...(gradingsRes || [])];

      setStoredGradings(gradings);
    },
    [showBoundary, setStoredGradings]
  );

  const rectsToGradings = useCallback(
    async (rects: i.Image[], caching: api.CustomCachingPolicy = false) => {
      if ((rects || []).length === 0) return;

      const imageIds = rects.map((r) => r.uuid);
      if (imageIds.length === 0) return;

      await getGradingsForImages(imageIds, caching);
    },
    [getGradingsForImages]
  );

  useEffect(() => {
    getImagesForSession(!forceImageReload.current);
    forceImageReload.current = false;
  }, [routeParams.sessionId, getImagesForSession]);

  useEffect(() => {
    rectsToGradings(storedRects);
  }, [storedRects, rectsToGradings, getGradingsForImages]);

  useEffect(() => {
    const getProducers = async () => {
      const producers = await api.getProducers({ onError: showBoundary });
      setProducers(producers || []);
    };
    getProducers();
  }, [showBoundary]);

  useEffect(() => {
    setTableItems(
      storedRects
        .sort((a, b) => a.camera - b.camera)
        .sort(
          (a, b) =>
            new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
        )
        .map((r, rInd) => {
          // Currently assuming a 1-1 relationship between an image and a grading
          const tmpGrading = storedGradings.find((g) => g.imageId === r.uuid);

          return {
            key: rInd + 1,
            image: r,
            imageRect: r,
            grading: tmpGrading,
          };
        })
    );
  }, [storedRects, storedGradings, setTableItems]);

  const columns: TableColumnsType<(typeof tableItems)[0]> | undefined =
    useMemo(() => {
      return [
        {
          title: "Image",
          dataIndex: "image",
          key: "image",
          width: 200,
          render: (image, _, __) => (
            <Image width={250} src={imageDataToUrl(image)} />
          ),
        },
        {
          title: "Image Info",
          dataIndex: "imageRect",
          key: "imageRect",
          width: 300,
          render: (image, _, __) => (
            <Descriptions
              column={1}
              items={[
                {
                  key: "1",
                  label: "Timestamp",
                  children: new Date((image.timestamp ?? 0) * 1000)
                    .toISOString()
                    .slice(11, 19),
                },
                { key: "2", label: "Camera", children: image.camera },
                {
                  key: "3",
                  label: "Tag",
                  children: (
                    <EditableTag
                      tag={image.tag || "Unknown"}
                      onNewTag={async (tag) => {
                        const img = { ...image, tag };

                        const variables: i.UpdateImageMutationVariables = {
                          uuid: img.uuid,
                          camera: img.camera,
                          sessionId: img.sessionId,
                          timestamp: img.timestamp,
                          x: img.x,
                          y: img.y,
                          width: img.width,
                          height: img.height,
                          rotation: img.rotation,
                          tag: img.tag,
                          view: img.view,
                        };

                        // If an existing img exists then update it
                        await api.updateImage({
                          onError: showBoundary,
                          variables,
                        });

                        // Reload the images for the session
                        await getImagesForSession("no-cache"); // Deactivate cache usage for this request
                      }}
                    />
                  ),
                },
                { key: "4", label: "View", children: image.view || "Unknown" },
                {
                  key: "5",
                  label: "Session",
                  children: (
                    <Link
                      to={`/sessions/${image.producerId}/${image.sessionId}`}
                    >
                      {image.sessionId}
                    </Link>
                  ),
                },
                {
                  key: "6",
                  label: "Producer",
                  children:
                    producers.find((p) => p.id === image.producerId)?.name ||
                    "Unknown",
                },
              ]}
            />
          ),
        },
        {
          title: "Grading",
          dataIndex: "grading",
          key: "grading",
          width: "45%",
          render: (grading, record, __) => {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div style={{ width: "100%" }}>
                  <Segmented
                    block
                    disabled={loadingGradingInfo}
                    options={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9]}
                    value={!grading?.discarded ? grading?.grading ?? -1 : -1}
                    onChange={async (value) => {
                      setLoadingGradingInfo(true);
                      if (
                        (grading?.grading || -1) < 0 &&
                        typeof grading?.discarded !== "boolean"
                      ) {
                        // Create a new grading
                        await api.createGrading({
                          variables: {
                            grading: parseInt(value),
                            imageId: record.image.uuid,
                            discarded: false,
                          },
                          onError: showBoundary,
                        });
                      } else {
                        // Update the grading
                        await api.updateGrading({
                          variables: {
                            grading: parseInt(value),
                            uuid: grading.uuid,
                            discarded: false,
                          },
                          onError: showBoundary,
                        });
                      }

                      await rectsToGradings(storedRects, false);
                      setTimeout(() => setLoadingGradingInfo(false), 500);
                    }}
                  />
                </div>
                <div>
                  <Button
                    danger={!grading?.discarded}
                    disabled={grading?.discarded || loadingGradingInfo}
                    onClick={async (value) => {
                      setLoadingGradingInfo(true);
                      if (
                        (grading?.grading || -1) < 0 &&
                        typeof grading?.discarded !== "boolean"
                      ) {
                        // Create a new grading
                        await api.createGrading({
                          variables: {
                            grading: grading?.grading || -1,
                            imageId: record.image.uuid,
                            discarded: true,
                          },
                          onError: showBoundary,
                        });
                      } else {
                        // Update the grading
                        await api.updateGrading({
                          variables: {
                            grading: -1,
                            uuid: grading?.uuid || record.image.uuid,
                            discarded: true,
                          },
                          onError: showBoundary,
                        });
                      }

                      await rectsToGradings(storedRects, false);
                      setTimeout(() => setLoadingGradingInfo(false), 500);
                    }}
                  >
                    {grading?.discarded ? "Discarded" : "Discard"}
                  </Button>
                </div>
              </div>
            );
          },
        },
      ];
    }, [
      storedRects,
      producers,
      loadingGradingInfo,
      rectsToGradings,
      showBoundary,
      setLoadingGradingInfo,
      getImagesForSession,
    ]);

  return (
    <div>
      <Button
        disabled={
          !routeParams.sessionId ||
          storedGradings.filter((g) => !g.discarded).length === 0 ||
          exportingData
        }
        loading={exportingData}
        type="primary"
        style={{ marginBottom: 15 }}
        onClick={async () => {
          if (!routeParams.sessionId) return;

          setExportingData(true);

          const gradingsRes = await api.exportGradingsForSession({
            variables: { sessionId: routeParams.sessionId },
            caching: false,
            onError: showBoundary,
          });

          // Now download the file if we have a link
          if (gradingsRes) {
            await axios({
              url: gradingsRes,
              method: "GET",
              responseType: "blob", // important
            }).then((response) => {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute(
                "download",
                gradingsRes.split("/").pop() || "file.csv"
              );
              document.body.appendChild(link);
              link.click();
            });
          }

          setExportingData(false);
        }}
      >
        {exportingData ? "Exporting Data..." : "Export Data"}
      </Button>
      <Table dataSource={tableItems} columns={columns} />
    </div>
  );
};
