import React from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";

import * as api from "api";
import * as i from "interfaces";

export const useSelf = () => {
  const { user, signOut: amplifySignOut } = useAuthenticator((context) => [
    context.user,
  ]);
  const [self, setSelf] = React.useState<i.GetSelfQuery["getSelf"]>(undefined);

  const useCacheRef = React.useRef(false);

  React.useEffect(() => {
    const getSelf = async () => {
      const self = await api.getSelf({
        onError: console.error,
        // If we are loading a new user (i.e., when `useCacheRef` is new or reset on sign out),
        // then don't use the cache and load the info from the server!
        caching: useCacheRef.current,
      });
      setSelf(self || null);
      useCacheRef.current = true;
    };
    getSelf();
  }, [user]);

  const signOut = () => {
    amplifySignOut();
    console.log("signed out");
    setSelf(null);
    useCacheRef.current = false;
  };

  return { self, signOut };
};
