import { RefObject, useRef } from "react";
import {
  MediaControlBar,
  MediaPlayButton,
  MediaPlaybackRateButton,
  MediaSeekBackwardButton,
  MediaSeekForwardButton,
  MediaTimeDisplay,
  MediaTimeRange,
  MediaCaptionsButton,
} from "media-chrome/react";
import {
  CaretLeftOutlined,
  CaretRightOutlined,
  StepBackwardOutlined,
  StepForwardOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";

import { CANVAS_OVERFLOW } from "./session";
import { Tooltip } from "antd";

const TOOLTIP_INNER_STYLE = {
  fontSize: 12.5,
  paddingLeft: 9,
  paddingRight: 9,
  paddingTop: 4.5,
  paddingBottom: 4.5,
};

const TOOLTIP_COLOR = "rgba(20, 20, 30, .7";

const TOOLTIP_ICON_STYLE = {
  paddingLeft: 10,
  paddingRight: 10,
  paddingTop: 15,
  paddingBottom: 15,
};

export type SessionControllbarProps = {
  frameStepDeactivated?: boolean;
  mediaSkipBackwardRef: RefObject<HTMLElement>;
  mediaSkipForwardRef: RefObject<HTMLElement>;
  mediaSeekRef: RefObject<HTMLElement>;
};

export const SessionControlbar = ({
  frameStepDeactivated,
  mediaSkipBackwardRef,
  mediaSkipForwardRef,
  mediaSeekRef,
}: SessionControllbarProps) => {
  const mediaInfoRef = useRef<HTMLElement>(null);

  return (
    <MediaControlBar
      mediacontroller="controller"
      style={{ width: "100%", marginTop: CANVAS_OVERFLOW / 2 }}
    >
      <MediaPlayButton mediacontroller="controller" />
      <MediaSeekBackwardButton
        ref={mediaSkipBackwardRef}
        mediacontroller="controller"
        notooltip
        disabled={true}
        style={{ padding: 0 }}
      >
        <span slot="icon">
          <Tooltip
            title="Previous Tag"
            color={TOOLTIP_COLOR}
            overlayInnerStyle={TOOLTIP_INNER_STYLE}
          >
            <StepBackwardOutlined style={TOOLTIP_ICON_STYLE} />
          </Tooltip>
        </span>
      </MediaSeekBackwardButton>
      <MediaSeekBackwardButton
        seekoffset={`${1 / 10}`}
        mediacontroller="controller"
        notooltip
        disabled={frameStepDeactivated}
        style={{ padding: 0 }}
      >
        <span slot="icon">
          <Tooltip
            title="Step Back Frame"
            color={TOOLTIP_COLOR}
            overlayInnerStyle={TOOLTIP_INNER_STYLE}
          >
            <CaretLeftOutlined style={TOOLTIP_ICON_STYLE} />
          </Tooltip>
        </span>
      </MediaSeekBackwardButton>
      <MediaSeekBackwardButton seekoffset="5" mediacontroller="controller" />
      <MediaSeekForwardButton mediacontroller="controller" />
      <MediaSeekForwardButton
        seekoffset={`${1 / 10}`}
        mediacontroller="controller"
        notooltip
        disabled={frameStepDeactivated}
        style={{ padding: 0 }}
      >
        <span slot="icon">
          <Tooltip
            title="Step Forward Frame"
            color={TOOLTIP_COLOR}
            overlayInnerStyle={TOOLTIP_INNER_STYLE}
          >
            <CaretRightOutlined style={TOOLTIP_ICON_STYLE} />
          </Tooltip>
        </span>
      </MediaSeekForwardButton>
      <MediaSeekBackwardButton
        ref={mediaSkipForwardRef}
        mediacontroller="controller"
        notooltip
        disabled={true}
        style={{ padding: 0 }}
      >
        <span slot="icon">
          <Tooltip
            title="Next Tag"
            color={TOOLTIP_COLOR}
            overlayInnerStyle={TOOLTIP_INNER_STYLE}
          >
            <StepForwardOutlined style={TOOLTIP_ICON_STYLE} />
          </Tooltip>
        </span>
      </MediaSeekBackwardButton>
      <MediaTimeRange ref={mediaSeekRef} mediacontroller="controller" />
      <MediaTimeDisplay
        remaining={false}
        showduration={true}
        notoggle={true}
        mediacontroller="controller"
      />
      <MediaPlaybackRateButton
        rates="0.5 1 1.5 2 3 5"
        mediacontroller="controller"
      />
      <MediaCaptionsButton
        disabled
        notooltip
        ref={mediaInfoRef}
        style={{ padding: 0 }}
      >
        <span slot="icon">
          <Tooltip
            title={
              <>
                <div>Space: Play/Pause</div>
                <div>Left Arrow: Step Back Frame</div>
                <div>Right Arrow: Step Forward Frame</div>
              </>
            }
            color={TOOLTIP_COLOR}
            overlayInnerStyle={TOOLTIP_INNER_STYLE}
          >
            <QuestionCircleOutlined style={TOOLTIP_ICON_STYLE} />
          </Tooltip>
        </span>
      </MediaCaptionsButton>
    </MediaControlBar>
  );
};
