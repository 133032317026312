import {
  Button,
  Col,
  Descriptions,
  Image,
  Popconfirm,
  Row,
  Select,
  Typography,
} from "antd";
import { EditableTag } from "components/editable-tag";
import * as i from "interfaces";

export const SELECTION_PREVIEW_MAX_WIDTH_PX = 175;

export type AnimalViews = "side" | "front";
export type PreviewImageProps = {
  loading?: boolean;
  imageRect: Partial<i.Image>;
  setImageRect: (img: Partial<i.Image>) => any;
  imgPreviewData: string; // Base64 image to be previewed
  onSubmit?: (image: Partial<i.Image>) => any;
  onDiscard?: (image: Partial<i.Image>) => any;
  onDelete?: (image: Partial<i.Image>) => any;
  title?: string;
  onViewSelectionChange: (choice: AnimalViews) => any;
};

export const PreviewImage = ({
  loading,
  imageRect,
  setImageRect,
  imgPreviewData,
  onSubmit,
  onDiscard,
  onDelete,
  title,
  onViewSelectionChange,
}: PreviewImageProps) => {
  return (
    <div
      style={{
        width: `${SELECTION_PREVIEW_MAX_WIDTH_PX}px`,
        height: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      {title ? (
        <div>
          <Typography.Title style={{ margin: 5 }}>{title}</Typography.Title>
        </div>
      ) : null}
      <Image
        alt="Video selection preview"
        src={imgPreviewData}
        width={SELECTION_PREVIEW_MAX_WIDTH_PX}
        preview={false}
        style={{ boxShadow: "0px 0px 7px rgba(0, 0, 0, 0.4)" }}
      />
      <Descriptions
        column={1}
        items={[
          {
            key: "1",
            label: "Timestamp",
            children: new Date((imageRect.timestamp ?? 0) * 1000)
              .toISOString()
              .slice(11, 19),
          },
          { key: "2", label: "Camera", children: imageRect.camera },
          {
            key: "3",
            label: "Tag",
            children: (
              <EditableTag
                tag={imageRect.tag || "Unknown"}
                onNewTag={(tag) => setImageRect({ ...imageRect, tag })}
              />
            ),
          },
          {
            key: "4",
            label: "View",
            children: (
              <Select
                value={imageRect.view as AnimalViews | undefined}
                disabled={loading}
                variant="outlined"
                placement="topLeft"
                style={{ width: 120 }}
                options={[
                  {
                    value: "Side",
                    label: "Side",
                  },
                  {
                    value: "Front",
                    label: "Front",
                  },
                ]}
                onChange={onViewSelectionChange}
              />
            ),
          },
        ]}
      />
      <Col span={24} style={{ width: "100%" }}>
        <Row justify="space-around" align="middle" style={{ margin: 10 }}>
          <Button
            disabled={loading}
            type="primary"
            style={{ minWidth: 125 }}
            onClick={() => (onSubmit ? onSubmit(imageRect) : undefined)}
            key="confirm-selection"
          >
            {imageRect.uuid ? "Update" : "Confirm"}
          </Button>
        </Row>
        {imageRect.uuid ? (
          <Row justify="space-around" align="middle" style={{ margin: 10 }}>
            <Popconfirm
              title="Delete Image"
              description="Are you sure to delete this image?"
              onConfirm={() => (onDelete ? onDelete(imageRect) : undefined)}
              okText="Delete"
              okButtonProps={{ danger: true }}
              cancelText="Cancel"
            >
              <Button disabled={loading} style={{ minWidth: 125 }} danger>
                Delete
              </Button>
            </Popconfirm>
          </Row>
        ) : null}
        <Row justify="space-around" align="middle" style={{ margin: 10 }}>
          <Button
            disabled={loading}
            type="text"
            style={{ minWidth: 125, textDecoration: "underline" }}
            onClick={() => (onDiscard ? onDiscard(imageRect) : undefined)}
          >
            Close Preview
          </Button>
        </Row>
      </Col>
    </div>
  );
};
