import { useEffect, useState } from "react";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, Form, Modal, Table, Input } from "antd";
import { useErrorBoundary } from "react-error-boundary";
import { useNavigate } from "react-router-dom";

import * as api from "api";
import { useSelf } from "hooks";
import * as i from "interfaces";

export const ProducersList = () => {
  const { showBoundary } = useErrorBoundary();
  const [producers, setProducers] = useState<
    i.GetProducersQuery["getProducers"]
  >([]);
  const [sessions, setSessions] = useState<
    i.GetSessionsByUserIdQuery["getSessionsByUserId"]
  >([]);

  const [modalOpen, setModalOpen] = useState(false);
  const [form] = Form.useForm();
  const { self } = useSelf();
  const navigate = useNavigate();

  useEffect(() => {
    const getProducers = async () => {
      const producers = await api.getProducers({ onError: showBoundary });
      setProducers(producers || []);
    };
    getProducers();
  }, [showBoundary]);

  useEffect(() => {
    const getSessions = async () => {
      const sessions = await api.getSessionsByUserId({ onError: showBoundary });
      setSessions(sessions || []);
    };
    getSessions();
  }, [showBoundary]);

  const dataSource = producers.map((p) => ({
    key: p.id,
    name: p.name,
    numSessions: sessions.filter((s) => s.producerId === p.id).length,
  }));

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Sessions",
      dataIndex: "numSessions",
      key: "numSessions",
    },
  ];

  return (
    <>
      {self?.admin && (
        <Button
          style={{ marginBottom: 10 }}
          type="primary"
          onClick={() => setModalOpen(true)}
        >
          <PlusCircleOutlined />
          Create Producer
        </Button>
      )}
      <Table dataSource={dataSource} columns={columns} />
      <Modal
        title="Create Producer"
        open={modalOpen}
        onCancel={() => setModalOpen(false)}
        onOk={form.submit}
        okText="Create"
      >
        <Form
          form={form}
          onFinish={async (variables: { name: string }) => {
            await api.createProducer({ onError: showBoundary, variables });
            setModalOpen(false);
            navigate(0);
          }}
        >
          <Form.Item label="Name" name="name">
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
