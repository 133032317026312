import { QueryOptions } from "@apollo/client";
import { CustomCachingPolicy } from "api";

export const determineFetchPolicy = (caching?: CustomCachingPolicy) => {
  let fetchPolicy: QueryOptions["fetchPolicy"];
  if (caching !== undefined) {
    if (typeof caching === "boolean") {
      fetchPolicy = caching ? "cache-first" : "no-cache";
    } else {
      fetchPolicy = caching;
    }
  }

  return fetchPolicy;
};
