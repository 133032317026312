import React from "react";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, Col, Form, List, Modal, Row, Select, Typography } from "antd";
import { useErrorBoundary } from "react-error-boundary";
import { useNavigate, useParams } from "react-router-dom";

import * as api from "api";
import * as i from "interfaces";

export const UserDetail = () => {
  const { showBoundary } = useErrorBoundary();
  const { id: stringId } = useParams<{ id: string }>();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [form] = Form.useForm();
  const [user, setUser] = React.useState<i.GetUserQuery["getUser"] | null>(
    null
  );
  const [producers, setProducers] = React.useState<
    i.GetProducersQuery["getProducers"]
  >([]);
  const navigate = useNavigate();

  if (!stringId) {
    throw new Error("id is required");
  }
  const id = parseInt(stringId, 10);

  const existingProducerIds = user?.producers.map((p) => p.id) || [];
  const producersOptions = producers
    .filter((p) => !existingProducerIds.includes(p.id))
    .map((p) => ({ value: p.id, label: p.name }));

  React.useEffect(() => {
    const getUser = async () => {
      const user = await api.getUser({
        variables: { id },
        onError: showBoundary,
      });
      setUser(user || null);
    };
    const getProducers = async () => {
      const producers = await api.getProducers({ onError: showBoundary });
      setProducers(producers || []);
    };
    getUser();
    getProducers();
  }, [id, showBoundary]);

  return (
    <>
      <Row style={{ marginTop: 30 }}>
        <Col xs={8} offset={8}>
          <Typography.Title level={2}>{user?.name}</Typography.Title>
          <List
            dataSource={user?.producers}
            header={<Typography.Title level={4}>Producers</Typography.Title>}
            footer={
              <Button
                type="text"
                style={{ float: "right" }}
                onClick={() => setModalOpen(true)}
              >
                <PlusCircleOutlined />
                Associate with Producer
              </Button>
            }
            renderItem={(p) => <List.Item>{p.name}</List.Item>}
          />
        </Col>
      </Row>
      <Modal
        title={`Associate ${user?.name || ""} with Producer`}
        open={modalOpen}
        onCancel={() => setModalOpen(false)}
        onOk={form.submit}
        okText="Associate"
      >
        <Form
          form={form}
          onFinish={async (values: { producerId: number }) => {
            await api.associateProducerUser({
              onError: showBoundary,
              variables: { producerId: values.producerId, userId: id },
            });
            setModalOpen(false);
            navigate(0);
          }}
        >
          <Form.Item label="Producer" name="producerId">
            <Select options={producersOptions} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
