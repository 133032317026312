import {
  Descriptions,
  Image,
  Spin,
  Table,
  TableColumnsType,
  Typography,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import TimeAgo from "react-timeago";
import * as i from "interfaces";
import { imageDataToUrl } from "utils";
import { EditableTag } from "components/editable-tag";

export type PreviousImageProps = {
  imageRects: i.Image[];
  onClick?: (image: i.Image) => any;
  title?: string;
  updateImage: (image: i.Image) => any;
};

export const PreviousImages = ({
  imageRects,
  onClick,
  title,
  updateImage,
}: PreviousImageProps) => {
  const tableItems: {
    image: i.Image;
    imageRect: i.Image;
  }[] = imageRects
    .sort((a, b) => a.camera - b.camera)
    .sort(
      (a, b) =>
        new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
    )
    .map((ir, irInd) => ({
      key: irInd,
      image: ir,
      imageRect: ir,
    }));

  const columns: TableColumnsType<(typeof tableItems)[0]> = [
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      width: 200,
      render: (image, _, __) => (
        <Image
          preview={false}
          width={250}
          src={imageDataToUrl(image)}
          placeholder={
            <Spin
              size="large"
              indicator={
                <LoadingOutlined
                  spin
                  style={{
                    fontSize: 128,
                  }}
                />
              }
              style={{
                padding: 10,
                borderRadius: 10,
                background: "rgba(0, 0, 0, 0.4)",
              }}
            />
          }
        />
      ),
    },
    {
      title: "Image Info",
      dataIndex: "imageRect",
      key: "imageRect",
      width: 300,
      render: (image, _, __) => (
        <Descriptions
          column={1}
          items={[
            {
              key: "1",
              label: "Timestamp",
              children: new Date(image.timestamp * 1000)
                .toISOString()
                .slice(11, 19),
            },
            { key: "2", label: "Camera", children: image.camera },
            {
              key: "3",
              label: "Tag",
              children: (
                <EditableTag
                  tag={(image.tag as string) || "Unknown"}
                  onNewTag={(tag) => updateImage({ ...image, tag })}
                />
              ),
            },
            { key: "4", label: "View", children: image.view || "Unknown" },
            {
              key: "5",
              label: "Created",
              children: <TimeAgo date={image.createdAt} />,
            },
            {
              key: "6",
              label: "Updated",
              children: <TimeAgo date={image.updatedAt} />,
            },
          ]}
        />
      ),
    },
  ];

  return (
    <div>
      {title ? (
        <div>
          <Typography.Title>{title}</Typography.Title>
        </div>
      ) : null}
      <Table
        onRow={(record, _) => {
          return {
            onClick: onClick ? () => onClick(record.image) : undefined,
            style: { cursor: "pointer" },
          };
        }}
        dataSource={tableItems}
        columns={columns}
      />
    </div>
  );
};
