import React from "react";
import {
  PictureOutlined,
  PlayCircleOutlined,
  TagOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { Layout, Menu, MenuProps } from "antd";
import { Link, useLocation } from "react-router-dom";

import { useSelf } from "hooks";

export const Sider: React.FC = () => {
  const { self } = useSelf();
  const { pathname } = useLocation();
  const selected = pathname.split("/")[1];

  const items: MenuProps["items"] = [
    {
      key: "sessions",
      label: <Link to="/sessions">Sessions</Link>,
      icon: <PlayCircleOutlined />,
    },
    {
      key: "producers",
      label: <Link to="/producers">Producers</Link>,
      icon: <TagOutlined />,
    },
  ];

  if (self?.admin) {
    items.unshift({
      key: "images",
      label: <Link to="/images">Images</Link>,
      icon: <PictureOutlined />,
    });
    items.push({
      key: "users",
      label: <Link to="/users">Users</Link>,
      icon: <TeamOutlined />,
    });
  }

  return (
    <Layout.Sider theme="light">
      <Menu
        theme="light"
        selectedKeys={[selected]}
        mode="inline"
        items={items}
      />
    </Layout.Sider>
  );
};
